import './about-priview.styles.css';
import {withTranslation} from "react-i18next";
import ONama from "../home-priview/o-nama/o-nama";
import PlatformeZaDonacije from "../home-priview/platforma-za-donacije/platforma-za-donacije";

const AboutPriview = (props) => {
    const {t, i18n} = props;
    return (
        <div style={{marginTop: "-80px"}}>
            {/*<h1 style={{textAlign: "center"}}>{t('about')}</h1>*/}
            <ONama pib="false"/>
            <PlatformeZaDonacije/>
        </div>
    );
}

export default withTranslation()(AboutPriview);
