import './contact-priview.styles.css';
import {withTranslation} from "react-i18next";

const ContactPriview = (props) => {
    const { t, i18n } = props;
    return (
        <div>
            <h2>{t('welcome')} Contact</h2>
        </div>
    );
}

export default withTranslation()(ContactPriview);
