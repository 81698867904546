import { Outlet } from "react-router-dom";
import AboutPriview from "../../components/about-priview/about-priview";

const About = () => {
    return (
        <div>
            <Outlet />
            <AboutPriview></AboutPriview>
        </div>
    )
}

export default About;
