import React, {useState} from "react";
import {
    FaEnvelope,
    FaFacebookF,
    FaTelegramPlane,
    FaViber,
    FaWhatsapp,
    FaTwitter
} from "react-icons/fa";
import {motion} from 'framer-motion';
import {withTranslation} from "react-i18next";
import {
    FacebookShareButton,
    ViberShareButton,
    WhatsappShareButton,
    TelegramShareButton,
    EmailShareButton
} from 'react-share';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const ShareModal = (props) => {
    const {t, i18n, showModal, setShowModal, akcija} = props;
    const icons = (size) => [
        {
            color: "#4267B2",
            icon: <FaFacebookF color="blue" size={size}/>,
        },
        {
            color: "#25D366",
            icon: <FaWhatsapp color="green" size={size}/>,
        },
        {
            color: "#0088cc",
            icon: <FaTelegramPlane color="blue" size={size}/>,
        },
        {
            color: "#59267c",
            icon: <FaViber color="indigo" size={size}/>,
        },
        {
            color: "#c71610",
            icon: <FaEnvelope color="red" size={size}/>,
        },
    ];

    const shareUrl = 'http://hemiias.me/akcija/' + akcija.id + '/' + akcija.slug;
    const title = akcija.naslov;
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    };

    return (
        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}
                    className="fixed inset-0 bg-modal_background z-40 flex justify-center items-center px-4">
            <motion.div initial={{scale: 0}} animate={{scale: 1}} exit={{scale: 0}}
                        transition={{duration: 0.4, type: "spring"}}
                        className="max-w-[500px] w-full max-h-[400px] h-full mx-auto bg-white rounded-2xl shadow-lg px-4">
                <div className="flex justify-between items-center border-b-2 h-16">
                    <h2 className="text-2xl font-bold">{t('share')}</h2>

                    <div
                        className="w-[40px] cursor-pointer"
                        onClick={() => setShowModal({open: false, link: ""})}
                    >
                        <img
                            className="w-full h-full object-contain"
                            src="/img/icons/close.svg"
                            alt="close"
                        />
                    </div>
                </div>

                <div>
                    <div>
                        <p className="font-bold text-2xl mt-3 mb-8">{t('share_over')}</p>

                        <div className="flex justify-between items-center">
                            <FacebookShareButton url={shareUrl} quote={title}>
                                <div
                                    className={`cursor-pointer border w-16 aspect-square rounded-full grid place-content-center hover:scale-90 transition-transform`}
                                    style={{borderColor: "#4267B2"}}
                                >
                                    <FaFacebookF color="blue" size="30"/>
                                </div>
                            </FacebookShareButton>

                            <ViberShareButton url={shareUrl} title={title}>
                                <div
                                    className={`cursor-pointer border w-16 aspect-square rounded-full grid place-content-center hover:scale-90 transition-transform`}
                                    style={{borderColor: "#59267c"}}
                                >
                                    <FaViber color="indigo" size="30"/>
                                </div>
                            </ViberShareButton>

                            <WhatsappShareButton url={shareUrl} title={title}>
                                <div
                                    className={`cursor-pointer border w-16 aspect-square rounded-full grid place-content-center hover:scale-90 transition-transform`}
                                    style={{borderColor: "#25D366"}}
                                >
                                    <FaWhatsapp color="green" size="30"/>
                                </div>
                            </WhatsappShareButton>

                            <TelegramShareButton url={shareUrl} title={title}>
                                <div
                                    className={`cursor-pointer border w-16 aspect-square rounded-full grid place-content-center hover:scale-90 transition-transform`}
                                    style={{borderColor: "#0088cc"}}
                                >
                                    <FaTelegramPlane color="blue" size="30"/>
                                </div>
                            </TelegramShareButton>

                            <EmailShareButton url={shareUrl} title={title}>
                                <div
                                    className={`cursor-pointer border w-16 aspect-square rounded-full grid place-content-center hover:scale-90 transition-transform`}
                                    style={{borderColor: "#c71610"}}
                                >
                                    <FaEnvelope color="red" size="30"/>
                                </div>
                            </EmailShareButton>
                        </div>

                        <div>
                            <p className="mt-4 mb-6 font-bold text-xl">{t('or_copy')}</p>

                            {isCopied && <p>Text Copied!</p>}
                            <div className="border border-gray-300 rounded-lg grid grid-cols-5 p-2 h-16">
                                <input
                                    className="col-span-4 px-2 font-medium"
                                    type="text"
                                    defaultValue={shareUrl}
                                />
                                <CopyToClipboard text={shareUrl} onCopy={handleCopy}>
                                    <button
                                        className="bg-primary col-span-1 text-white font-bold rounded-xl hover:bg-secondary">
                                        {t('copy')}
                                    </button>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </motion.div>
    );
};

export default withTranslation()(ShareModal);
