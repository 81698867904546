import React from "react";
import {navItems} from "./nav-utils";
import {Link} from "react-router-dom";
import {
    FaPaypal,
    FaCcMastercard,
    FaCcVisa,
    FaCcAmex
} from "react-icons/fa";
import {withTranslation} from "react-i18next";

const Footer = (props) => {
    const {t, i18n} = props;

    return (
        <div className="mt-12 bg-ternary px-4">
            <div className="max-w-[1100px] mx-auto py-10">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 lg:gap-20">
                    <div className="text-center lg:text-left">
                        <h4 className="font-bold text-2xl mb-4">Hemiias</h4>
                        <p>{t('footer_desc')}</p>
                        <p>Naziv pravnog lica: Nevladina fondacija “Hemiias Montenegro”</p>
                    </div>
                    <div className="text-center lg:text-left">
                        <h4 className="font-bold text-2xl mb-4">{t('menu')}</h4>

                        <ul>
                            {/*{navItems.left.concat(navItems.right).map((item, index) => (
                                <li key={index} className="hover:text-primary">
                                    <Link to={item.link}>{item.label}</Link>
                                </li>
                            ))}*/}
                            {/*<li className="hover:text-primary">
                                <Link to="/uslovi-koriscenja">Uslovi korišćenja</Link>
                            </li>*/}
                            <li className="hover:text-primary">
                                <Link to="/akcije">{t('actions')}</Link>
                            </li>
                            <li className="hover:text-primary">
                                <Link to="/zavrsene-akcije">{t('actions_end')}</Link>
                            </li>
                            <li className="hover:text-primary">
                                <Link to="/about">{t('about')}</Link>
                            </li>
                            <li className="hover:text-primary">
                                <Link to="/uslovi-koriscenja">{t('terms_payment')}</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="text-center lg:text-left">
                        <h5 className="font-bold text-2xl mb-4">{t('contact')}</h5>

                        <ul className="grid gap-3 justify-center lg:justify-start">
                            <li className="flex items-center gap-2 font-medium">
								<span>
								  <img
                                      className="w-[24px] h-full object-contain"
                                      src="/img/icons/footer/email.svg"
                                      alt="email"
                                  />
								</span>
                                hemiias.cg@gmail.com
                            </li>
                            <li className="flex items-center gap-2 font-medium">
								<span>
								  <img
                                      className="w-[24px] h-full object-contain"
                                      src="/img/icons/footer/phone.svg"
                                      alt="phone"
                                  />
								</span>
                                +38268806645
                            </li>
                            <li className="flex items-center gap-2 font-medium">
								<span>
								  <img
                                      className="w-[24px] h-full object-contain"
                                      src="/img/icons/footer/location.svg"
                                      alt="location"
                                  />
								</span>
                                Adresa: Bar, Ilino bb, {t('montenegro')} <br/>
                                {t('mon-sat')} 08-16h
                            </li>
                            <li className="flex items-center gap-2 font-medium">
                                <div
                                    className={`cursor-pointer border w-4 aspect-square rounded-full grid place-content-center hover:scale-90 transition-transform`}
                                    /*style={{borderColor: "#0047AB"}}*/
                                >
                                    {/*<FaCcVisa color="0047AB" size="20"/>*/}
                                    <img
                                        src="/img/cards/visa.svg" alt=""/>
                                </div>
                                <div
                                    className={`cursor-pointer border w-4 aspect-square rounded-full grid place-content-center hover:scale-90 transition-transform`}
                                >
                                    <img src="/img/cards/master.svg" alt=""/>
                                </div>
                                <div
                                    className={`cursor-pointer border w-4 aspect-square rounded-full grid place-content-center hover:scale-90 transition-transform`}
                                >
                                    <img src="/img/cards/maestro.svg" alt=""/>
                                </div>
                                <div
                                    className={`cursor-pointer border w-4 aspect-square rounded-full grid place-content-center hover:scale-90 transition-transform`}
                                    /*style={{borderColor: "#D50000"}}*/
                                >
                                    {/*<FaCcMastercard color="D50000" size="20"/>*/}
                                    <a href="https://www.allsecure.rs/" target="_blank"><img
                                        src="/img/cards/allsecure.svg" alt="" style={{height: "40px"}}/></a>
                                </div>
                            </li>
                            <li className="flex items-center gap-2 font-medium">
                                <div
                                    className={`cursor-pointer border w-4 aspect-square rounded-full grid place-content-center hover:scale-90 transition-transform`}
                                    /*style={{borderColor: "#D50000"}}*/
                                >
                                    {/*<FaCcMastercard color="D50000" size="20"/>*/}
                                    <a href="https://www.nlb.me/" target="_blank"><img
                                        src="/img/cards/nlb-logo.svg" alt="" style={{height: "40px"}}/></a>
                                </div>
                                <div
                                    className={`cursor-pointer border w-4 aspect-square rounded-full grid place-content-center hover:scale-90 transition-transform`}
                                >
                                    <img src="/img/cards/visa_secure.svg" alt=""/>
                                </div>
                                <div
                                    className={`cursor-pointer border w-4 aspect-square rounded-full grid place-content-center hover:scale-90 transition-transform`}
                                >
                                    <img src="/img/cards/mc_idcheck.svg" alt=""/>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="flex justify-center lg:block">
                        <img
                            src="/img/hemiias_logo.svg"
                            className="w-[180px] lg:w-full object-contain"
                            alt="logo"
                        />
                    </div>
                </div>
            </div>
            <div className="max-w-[1100px] mx-auto py-10 text-center">
                © <a href="https://www.mmhub.me/" target="_blank">mmhub.me</a>
            </div>
        </div>
    );
};

export default withTranslation()(Footer);
