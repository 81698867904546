import {withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Doniraj from "../akcije-priview/doniraj/doniraj";
import PrikupljeneDonacije from "../akcije-priview/prikupljene-donacije/prikupljene-donacije";
import {AnimatePresence} from "framer-motion";
import ShareModal from "../home-priview/istaknute-akcije/share-modal";

const ZavrseneAkcijePriview = (props) => {
    const {t, i18n} = props;
    const {REACT_APP_API_URL, REACT_APP_BACKEND} = process.env;
    const navigate = useNavigate();

    const [zatvoreneAkcije, setZatvoreneAkcije] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [showModal, setShowModal] = useState({
        open: false,
        link: "",
        akcija: {}
    });

    const getZatvoreneAkcije = async () => {
        fetch(REACT_APP_API_URL + "akcije-zatvorene?lng=" + i18n.language)
            .then((response) => response.json())
            .then((result) => {
                setZatvoreneAkcije(result.data);
                setLastPage(result.last_page);
            });
    };

    const getMoreAkcija = () => {
        const page = currPage + 1;
        fetch(
            REACT_APP_API_URL +
            "akcije-zatvorene?page=" +
            page +
            "&lng=" +
            i18n.language
        )
            .then((response) => response.json())
            .then((result) => {
                setCurrPage(result.current_page);
                setZatvoreneAkcije([...zatvoreneAkcije, ...result.data]);
            });
    };

    useEffect(() => {
        fetch(REACT_APP_API_URL + "akcije-zatvorene?lng=" + i18n.language)
            .then((response) => response.json())
            .then((result) => {
                console.log(result.data);
                setZatvoreneAkcije(result.data);
                setLastPage(result.last_page);
            });
    }, [i18n.language]);

    function detailsAction(action) {
        navigate("/akcija/" + action.id + "/" + action.slug);
    }

    const progress = "absolute top-0 left-0 h-full";

    return (
        <div>
            <div className="mt-16">
                <div>
                    <h1 className="font-bold text-3xl md:text-4xl text-center">{t('actions_end')}</h1>

                    <div className="mt-16">
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 max-w-[1100px] mx-auto">
                            {zatvoreneAkcije.map((zavrsenaAkcija, index) => (
                                <div key={index}>
                                    <div className="shadow-xl rounded-xl overflow-hidden">
                                        <div>
                                            <div className="h-[200px]">
                                                <Link
                                                    to={`/akcija/${zavrsenaAkcija.id}/${zavrsenaAkcija.slug}`}
                                                    className="h-[200px]"
                                                >
                                                    <img
                                                        src={REACT_APP_BACKEND + "" + zavrsenaAkcija.slika}
                                                        alt="test"
                                                        className="w-full h-full object-cover"
                                                    />
                                                </Link>
                                            </div>

                                            <div className="mt-10 px-4 pb-5">
                                                <div>
                                                    <Link
                                                        to={`/akcija/${zavrsenaAkcija.id}/${zavrsenaAkcija.slug}`}
                                                        className="text-xl font-bold text-center mb-4"
                                                    >
                                                        <h4 className="text-2xl font-bold text-center mb-4">
                                                            {zavrsenaAkcija.naslov}
                                                        </h4>
                                                    </Link>
                                                    <p
                                                        className="text-center text-gray-500"
                                                        dangerouslySetInnerHTML={{
                                                            __html: zavrsenaAkcija.sadrzaj,
                                                        }}
                                                    ></p>

                                                    <div className="mt-4 flex justify-center items-center flex-wrap">
                                                        <button
                                                            onClick={() => detailsAction(zavrsenaAkcija)}
                                                            className="py-2 w-full mx-6 bg-primary rounded-xl text-white font-semibold hover:bg-secondary"
                                                        >
                                                            {zavrsenaAkcija.active === 1 ? t('donate') : t('review')}
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="mt-8 flex justify-between items-center gap-6">
                                                    <div className="w-full">
                                                        <div className="relative w-full h-[20px] bg-gray-200">
                                                            <div
                                                                style={{
                                                                    width: `${
                                                                        progress +
                                                                        Math.round(
                                                                            (zavrsenaAkcija.prikupljeno_novca * 100) /
                                                                            zavrsenaAkcija.potrebno_novca
                                                                        )
                                                                    }%`,
                                                                }}
                                                                className={"bg-primary"}
                                                            ></div>
                                                        </div>
                                                        <p className="mt-2">
                              <span className="font-bold">
                                {zavrsenaAkcija.prikupljeno_novca.toLocaleString(
                                    undefined,
                                    {
                                        maximumFractionDigits: 2,
                                    }
                                )} €
                              </span>{" "}
                                                            od{" "}
                                                            {zavrsenaAkcija.potrebno_novca.toLocaleString(
                                                                undefined,
                                                                {
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            )} €
                                                        </p>
                                                    </div>

                                                    <div
                                                        className="flex justify-center flex-col items-center cursor-pointer"
                                                        onClick={() =>
                                                            setShowModal({
                                                                open: true,
                                                                link: "test123",
                                                                akcija: zavrsenaAkcija
                                                            })
                                                        }
                                                    >
                                                        <div className="w-[26px] shrink-0">
                                                            <img
                                                                src="/img/icons/share.svg"
                                                                alt="share"
                                                                className="w-full h-full"
                                                            />
                                                        </div>
                                                        <span className="text-sm text-center">
                                                          Podijeli
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="text-center mt-14">
                            {currPage !== lastPage ? (
                                <button
                                    onClick={() => getMoreAkcija()}
                                    className="px-12 py-2 bg-primary hover:bg-secondary text-white font-bold rounded-lg"
                                >
                                    Učitajte još akcija
                                </button>
                            ) : (
                                <p className="px-12 py-2 text-black font-bold rounded-lg">
                                    <span style={{display: "none"}}>Nema više akcija</span>
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Doniraj/>
            <PrikupljeneDonacije isSingle="false"/>

            <AnimatePresence>
                {showModal.open && (
                    <ShareModal showModal={showModal} setShowModal={setShowModal} akcija={showModal.akcija}/>
                )}
            </AnimatePresence>
        </div>
    );
};

export default withTranslation()(ZavrseneAkcijePriview);
