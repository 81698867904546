import "./home-priview.styles.css";
import {withTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Hero from "./hero/hero";
import IstaknuteAkcije from "./istaknute-akcije/istaknute-akcije";
import ONama from "./o-nama/o-nama";
import PlatformeZaDonacije from "./platforma-za-donacije/platforma-za-donacije";

const HomePriview = (props) => {
    const {t, i18n} = props;
    const {REACT_APP_API_URL, REACT_APP_BACKEND} = process.env;
    const [slideShow, setSlideShow] = useState([]);
    const [izdvojeneAkcije, setIzdvojeneAkcije] = useState([]);
    const [currPage, setCurrPage] = useState(1);

    const navigate = useNavigate();

    const getIzdvojeneAkcije = async () => {
        fetch(REACT_APP_API_URL + "akcije-izdvojene?lng=" + i18n.language)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                setIzdvojeneAkcije(result);
            });
    };

    const getMoreAkcija = () => {
        const page = currPage + 1;
        fetch(
            REACT_APP_API_URL +
            "akcije-read-more?page=" +
            page +
            "&lng=" +
            i18n.language
        )
            .then((response) => response.json())
            .then((result) => {
                setCurrPage(result.current_page);
                setIzdvojeneAkcije([...izdvojeneAkcije, ...result.data]);
            });
    };

    useEffect(() => {
        getIzdvojeneAkcije();
    }, [i18n.language]);

    const cardsAkcije = izdvojeneAkcije.map((akcija, index) => {
        return (
            <div className="card col-lg-3 col-md-6 col-sm-12" key={akcija.id}>
                <img
                    src={REACT_APP_BACKEND + "" + akcija.slika}
                    className="card-img-top"
                    alt="..."
                />
                <div className="card-body">
                    <h5 className="card-title">{akcija.naslov}</h5>
                    <p
                        className="card-text hide-text"
                        style={{height: "50px"}}
                        dangerouslySetInnerHTML={{__html: akcija.sadrzaj}}
                    ></p>
                    <div
                        className="progress"
                        role="progressbar"
                        aria-label="Basic example"
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >
                        <div
                            className="progress-bar"
                            style={{
                                width: `${(akcija.prikupljeno_novca * 100) / akcija.potrebno_novca}%`,
                            }}
                        ></div>
                    </div>
                    <div style={{padding: "5px"}}>
                        {akcija.prikupljeno_novca.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                        })} € {" "}
                        od{" "}
                        {akcija.potrebno_novca.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                        })} €
                    </div>
                    <a className="btn btn-primary" onClick={() => detailsAction(akcija)}>
                        Detail
                    </a>
                </div>
            </div>
        );
    });

    function detailsAction(action) {
        navigate("/akcija/" + action.id + "/" + action.slug);
    }

    return (
        <main>
            {/*<h2>{t('welcome')}</h2>*/}
            <Hero/>
            <IstaknuteAkcije/>
            <ONama pib="false"/>
            <PlatformeZaDonacije/>
        </main>
    );
};

export default withTranslation()(HomePriview);
