import React, {useState} from 'react';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import FizickoLice from "./tip/fizicko-lice";
import PravnoLice from "./tip/pravno-lice";

const PokreniAkciju = () => {
    const {t} = useTranslation();

    const [isFizicko, setIsFizicko] = useState(true);

    return (
        <div>
            <div className="mt-16">
                <h1 className="font-bold text-xl md:text-4xl text-center" style={{marginBottom: "33px"}}>
                    Pokreni akciju</h1>

                <div className="grid grid-cols-2 md:grid-cols-2 gap-8 max-w-[1100px] mx-auto">
                    <div className="w-full mb-6 md:mb-0" style={{textAlign: "right"}}>
                        {isFizicko ? (
                            <button
                                className="font-semibold text-lg bg-primary px-8 py-2 rounded-xl text-white cursor-pointer transition-colors hover:bg-secondary"
                                onClick={() => setIsFizicko(true)}>
                                {t("form_physically_entity")}
                            </button>
                        ) : (
                            <button
                                className="font-semibold text-lg bg-light px-8 py-2 rounded-xl text-black
                                cursor-pointer transition-colors hover:bg-secondary border border-gray-300"
                                onClick={() => setIsFizicko(true)}>
                                {t("form_physically_entity")}
                            </button>
                        )}
                    </div>
                    <div className="w-full mb-6 md:mb-0">
                        {!isFizicko ? (
                            <button
                                className="font-semibold text-lg bg-primary px-8 py-2 rounded-xl text-white cursor-pointer transition-colors hover:bg-secondary"
                                onClick={() => setIsFizicko(false)}>
                                {t("form_legal_entity")}
                            </button>
                        ) : (
                            <button
                                className="font-semibold text-lg bg-light px-8 py-2 rounded-xl text-black
                                 cursor-pointer transition-colors hover:bg-secondary border border-gray-300"
                                onClick={() => setIsFizicko(false)}>
                                {t("form_legal_entity")}
                            </button>
                        )}
                    </div>
                </div>

                {isFizicko ? (
                    <FizickoLice/>
                ) : (
                    <PravnoLice/>
                )}
            </div>
        </div>
    );
};

export default PokreniAkciju;