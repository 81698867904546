import {withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Doniraj from "../akcije-priview/doniraj/doniraj";
import PrikupljeneDonacije from "../akcije-priview/prikupljene-donacije/prikupljene-donacije";
import {Link} from "react-scroll";
import ShareModal from "../home-priview/istaknute-akcije/share-modal";
import {AnimatePresence} from "framer-motion";
import Gallery from "./gallery";
import {Kartica, SMS} from "../akcije-priview/icons";

const SinglePriview = (props) => {
    const {t, i18n} = props;
    const {REACT_APP_API_URL, REACT_APP_BACKEND} = process.env;
    const {id} = useParams();
    const [showModal, setShowModal] = useState({
        open: false,
        link: "",
    });
    const [topDonators, setTopDonators] = useState([]);
    const [akcija, setAkcija] = useState({
        id: "",
        naslov: "",
        sadrzaj: "",
        prikupljeno_novca: "",
        potrebno_novca: "",
        galerijaPath: "",
        slug: "",
        fotografije: [],
        video_linkovi: []
    });

    const getAkcija = async () => {
        fetch(REACT_APP_API_URL + "akcija/" + id + "?lng=" + i18n.language)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                setAkcija(result);
                getTopDonators(result.id);
            });
    }

    const getTopDonators = async (id) => {
        fetch(REACT_APP_API_URL + "top-ten/" + id + "?lng=" + i18n.language)
            .then((response) => response.json())
            .then((result) => {
                setTopDonators(result);
            });
    }

    useEffect(() => {
        getAkcija();
    }, [i18n.language]);

    return (
        <>
            <div>
                <div className="mt-16 px-4">
                    <div className="max-w-[1100px] mx-auto">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
                            <div>
                                <h1 className="text-4xl font-bold text-gray-900 mb-6">
                                    {akcija.naslov}
                                </h1>
                                <p dangerouslySetInnerHTML={{__html: akcija.sadrzaj}}></p>
                                <div>
                                    <div className="mt-8 flex justify-between items-center gap-6">
                                        <div className="w-full">
                                            <div className="relative w-full h-[20px] bg-gray-200">
                                                <div
                                                    className="absolute top-0 left-0 h-full bg-primary"
                                                    style={{
                                                        width: `${Math.round(
                                                            (akcija.prikupljeno_novca * 100) /
                                                            akcija.potrebno_novca
                                                        )}%`,
                                                    }}
                                                ></div>
                                            </div>
                                            <p className="mt-2">
                                                <span className="font-bold">
                                                  {akcija.prikupljeno_novca.toLocaleString(undefined, {
                                                      maximumFractionDigits: 2,
                                                  })} €
                                                </span>{" "}
                                                od {" "}
                                                {akcija.potrebno_novca.toLocaleString(undefined, {
                                                    maximumFractionDigits: 2,
                                                })} €
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-5 gap-3 flex justify-between items-center cursor-pointer w-full">
                                        <div className="w-full max-w-[200px] flex justify-center items-center">
                                            <div className="w-full max-w-[200px] flex justify-center items-center">
                                                {akcija.active === 1 &&
                                                    <Link
                                                        to="doniraj"
                                                        spy={true}
                                                        smooth={true}
                                                        duration={500}
                                                        className="py-2 w-full bg-primary rounded-xl text-white font-semibold hover:bg-secondary"
                                                    >
                                                        <span className="text-center block">{t('donate')}</span>
                                                    </Link>
                                                }
                                            </div>
                                        </div>

                                        <div
                                            className="flex flex-col justify-center items-center"
                                            onClick={() => setShowModal({...showModal, open: true})}
                                        >
                                            <div className="w-[26px] shrink-0">
                                                <img
                                                    src="/img/icons/share.svg"
                                                    alt="share"
                                                    className="w-full h-full"
                                                />
                                            </div>
                                            <span className="text-sm text-center">{t('share')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex">
                                <div className="w-full h-fit rounded-2xl overflow-hidden">
                                    <img
                                        src={REACT_APP_BACKEND + "" + akcija.slika}
                                        alt=""
                                        className="w-full"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Gallery videoLinkovi={akcija.video_linkovi} fotografije={akcija.fotografije}
                     galeryPath={akcija.galerijaPath}/>

            <AnimatePresence>
                {showModal.open && (
                    <ShareModal showModal={showModal} setShowModal={setShowModal} akcija={akcija}/>
                )}
            </AnimatePresence>

            {akcija.active === 1 &&
                <Doniraj/>
            }

            <div className="mt-16 pb-10 px-4">
                <div className="max-w-[1100px] mx-auto">
                    <h1 className="font-bold text-3xl md:text-4xl text-center">
                        {t('collected_donations')}
                    </h1>

                    <div className="grid grid-cols-1 lg:grid-cols-1 gap-8 mt-11">
                        <div style={{display: "none"}}>
                            <div className="flex justify-center items-center gap-2 bg-ternary rounded-lg py-4">
                                <h5 className="font-bold text-xl lg:text-2xl">SMS</h5>
                                <SMS color="#000" size={32}/>
                            </div>

                            <ul className="flex flex-col w-full gap-2 mt-2">
                                {new Array(7).fill(0).map((_, index) => (
                                    <li
                                        className="grid grid-cols-5 rounded-md bg-gray-100 gap-4 h-[82px] sm:h-[40px] overflow-hidden"
                                        key={index}
                                    >
                                        <div className="col-span-2 pl-6 flex items-center">
                                            <p>02. Mar 2023. 21:41h</p>
                                        </div>
                                        <div className="col-span-2 flex items-center">
                                            <p className="font-bold text-lg">382 67 789 789</p>
                                        </div>
                                        <div
                                            className="bg-ternary col-span-1 flex items-center justify-center font-bold">
                                            {index + 1} €
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div>
                            <div className="flex justify-center items-center gap-2 bg-ternary rounded-lg py-4">
                                <h5 className="font-bold text-xl lg:text-2xl">{t('cards_paid')}</h5>
                                <Kartica color="#000" size={32}/>
                            </div>

                            <ul className="flex flex-col w-full gap-2 mt-2">
                                {topDonators.map((donation, index) => (
                                    <li
                                        className="grid grid-cols-5 rounded-md gap-2 bg-gray-100 h-[82px] sm:h-[40px] overflow-hidden"
                                        key={index}
                                    >
                                        <div className="col-span-2 pl-6 flex items-center text-sm lg:text-base">
                                            <p>{donation.datePayment}h</p>
                                        </div>
                                        <div className="col-span-2 flex items-center">
                                            <p className="font-bold text-base lg:text-lg">{donation.firstName} {donation.lastName}</p>
                                        </div>
                                        <div
                                            className="bg-ternary col-span-1 flex items-center justify-center font-bold text-sm lg:text-base">
                                            {donation.iznos.toLocaleString(undefined, {maximumFractionDigits: 2})} €
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withTranslation()(SinglePriview);
