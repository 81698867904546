import React, {useState} from 'react';
import {Kartica, Racun, SMS} from "../../components/akcije-priview/icons";
import {withTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import PrikupljeneDonacije from "../../components/akcije-priview/prikupljene-donacije/prikupljene-donacije";

const Doniraj = (props) => {
    const {t, i18n} = props;
    const navigate = useNavigate();

    const [isChecked, setIsChecked] = useState(false);
    const [amount, setAmount] = useState('');
    const id = props.id ? props.id : 0;

    const handleChangeAmount = (event) => {
        event.preventDefault();
        setAmount(event.target.value);
    }

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!amount) {
            if (!amount)
                alert(t('amount_required'));
            /*if (!isChecked)
                alert(t('terms_required'));*/
            return;
        }
        // Get the form element
        const formElement = event.target;

        // Submit the form
        // formElement.submit();
        navigate('/payment/' + id + '/' + amount);
    };

    return (
        <div className="mt-20">
            <div className="max-w-[1100px] mx-auto px-4">
                <div className="mt-16">
                    {/*<h1 className="font-bold text-3xl md:text-4xl text-center">Doniraj</h1>*/}

                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8">
                        <div className="h-[320px] rounded-2xl bg-white shadow-lg overflow-hidden">
                            <div className="bg-primary flex justify-center gap-2 py-2 lg:py-4">
                                <h5 className="text-white font-medium text-xl lg:text-2xl text-white flex justify-center items-center">
                                    {t('fondation_cg')}
                                </h5>
                                <Racun color="#fff" size={38}/>
                            </div>
                            <div className="flex justify-center items-center flex-col pt-4 pb-8 px-4">
                                <div className="text-center">
                                    <label className="text-primary font-bold">{t('bank_account')}</label>
                                    <p className="font-bold text-md md:text-lg">530-164755-60</p>
                                    <p className="text-md md:text-lg">Banka: NLB Banka AD Podgorica</p>
                                </div>
                                <div className="text-center">
                                    <label className="text-primary font-bold">IBAN</label>
                                    <p className="font-bold text-md md:text-lg">ME25530085120007011961</p>
                                </div>
                                <div className="text-center">
                                    <label className="text-primary font-bold">SWIFT</label>
                                    <p className="font-bold text-md md:text-lg">MNBAMEPG</p>
                                </div>
                            </div>
                        </div>
                        <div className="h-[320px] rounded-2xl bg-white shadow-lg overflow-hidden">
                            <div className="bg-primary flex justify-center gap-2 py-2 lg:py-4">
                                <h5 className="text-white font-medium text-xl lg:text-2xl text-white flex justify-center items-center">
                                    {t('fondation_de')}
                                </h5>
                                <Racun color="#fff" size={38}/>
                            </div>
                            <div className="flex justify-center items-center flex-col pt-4 pb-8 px-4">
                                <div className="text-center">
                                    {/*<label className="text-primary font-bold">{t('bank_account')}</label>*/}
                                    <label className="text-primary font-bold">{t('payment_form_adress')}</label>
                                    <p className="font-bold text-md md:text-lg">Dorfsreaße 5 48612 Horstmar</p>
                                    <p className="text-md md:text-lg">Banka: Kreissparkasse Steinfurt</p>
                                </div>
                                <div className="text-center">
                                    <label className="text-primary font-bold">IBAN</label>
                                    <p className="font-bold text-md md:text-lg">DE40 4035 1060 0074 2327 52</p>
                                </div>
                                <div className="text-center">
                                    <label className="text-primary font-bold">BIC (SWIFT)</label>
                                    <p className="font-bold text-md md:text-lg">WELADED1STF</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-16">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8">
                        <div className="h-[320px] rounded-2xl bg-white shadow-lg overflow-hidden">
                            <div className="bg-primary flex justify-center gap-2 py-2 lg:py-4">
                                <h5 className="text-white font-medium text-xl lg:text-2xl text-white flex justify-center items-center">
                                    {t('pay_card')}
                                </h5>
                                <Kartica color="#fff" size={38}/>
                            </div>
                            <div className="flex justify-center items-center flex-col pt-10 pb-8 px-4">
                                <form onSubmit={handleSubmit}
                                      action={`http://localhost:3000/api/payment/0/${amount}`}>
                                    <div className="flex h-full gap-2 w-full sm:w-auto">
                                        <input
                                            type="text"
                                            placeholder={t('amount')}
                                            name="iznos"
                                            onChange={handleChangeAmount}
                                            className="px-2 border-2 rounded-2xl w-full sm:w-auto"
                                        />
                                        <span
                                            className="border-2 w-[30px] rounded-2xl aspect-square grid place-content-center text-lg font-bold">
                                            €
                                        </span>
                                    </div>
                                    {/*<div className="mt-4">
                                        <input
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="ml-1">{t('accept_terms')}</label>
                                    </div>*/}
                                    <div className="text-center mt-6">
                                        <button type="submit"
                                                className="px-12 py-2 bg-primary hover:bg-secondary text-white font-bold rounded-2xl">
                                            {t('donate')}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="h-[320px] rounded-2xl bg-white shadow-lg overflow-hidden">
                            <div className="bg-primary flex justify-center gap-2 py-2 lg:py-4">
                                <h5 className="text-white font-medium text-xl lg:text-2xl text-white flex justify-center items-center">
                                    {t('send_sms')}
                                </h5>
                                <SMS color="#fff" size={38}/>
                            </div>
                            <div className="grid place-content-center h-[78%] px-4">
                                <div className="text-center">
                                    <p className="text-2xl font-bold">
                                        {/*{t('type')} <span className="text-primary">10</span> {t('and_send')}{" "}
                                        <span className="text-primary">SMS</span> {t('on')}{" "}
                                        <span className="text-primary">14555</span>*/}
                                        Uskoro...
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PrikupljeneDonacije isSingle="false"/>
        </div>
    );
};

export default withTranslation()(Doniraj);