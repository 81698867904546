import React, {useState} from "react";
import {withTranslation} from "react-i18next";

const PlatformaZaDonacije = (props) => {
    const {t, i18n} = props;

    const [cards, setCards] = useState([
        {
            image: "/img/icons/heart.svg",
            title: t('heart_title'),
            description:
                t('heart_desc'),
        },
        {
            image: "/img/icons/card.svg",
            title: t('card_title'),
            description:
                t('card_desc'),
        },
        {
            image: "/img/icons/trust.svg",
            title: t('trust_title'),
            description:
                t('trust_desc'),
        },
    ]);

    return (
        <div className="mt-20 pb-8">
            <div className="max-w-[1000px] mx-auto px-4">
                <div className="text-center">
                    <h2 className="text-3xl md:text-4xl font-bold">
                        {t('hemiias_platform')}
                    </h2>
                    <p className="mt-6 text-gray-500">
                        {t('hemiias_platform_desc')}
                    </p>
                </div>

                <div className="mt-8">
                    <div className="grid sm:grid-cols-3 gap-4">
                        <div className="text-center">
                            <div className="grid place-content-center">
                                <div className="w-[90px]">
                                    <img
                                        className="w-full h-full"
                                        src="/img/icons/heart.svg"
                                        alt={t('heart_title')}
                                    />
                                </div>
                            </div>

                            <div>
                                <h3 className="font-bold text-xl mb-3 mt-1">{t('heart_title')}</h3>

                                <p className="text-base text-gray-500">{t('heart_desc')}</p>
                            </div>
                        </div>
                        <div className="text-center">
                            <div className="grid place-content-center">
                                <div className="w-[90px]">
                                    <img
                                        className="w-full h-full"
                                        src="/img/icons/card.svg"
                                        alt={t('card_title')}
                                    />
                                </div>
                            </div>

                            <div>
                                <h3 className="font-bold text-xl mb-3 mt-1">{t('card_title')}</h3>

                                <p className="text-base text-gray-500">{t('card_desc')}</p>
                            </div>
                        </div>
                        <div className="text-center">
                            <div className="grid place-content-center">
                                <div className="w-[90px]">
                                    <img
                                        className="w-full h-full"
                                        src="/img/icons/trust.svg"
                                        alt={t('trust_title')}
                                    />
                                </div>
                            </div>

                            <div>
                                <h3 className="font-bold text-xl mb-3 mt-1">{t('trust_title')}</h3>

                                <p className="text-base text-gray-500">{t('trust_desc')}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="text-center mt-12">
                    <a href="/pokreni-akciju"
                       className="py-3 px-14 text-base md:text-xl bg-primary hover:bg-secondary rounded-lg text-white font-bold">
                        {t('start_the_action')}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(PlatformaZaDonacije);
