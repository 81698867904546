import {withTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Akcije from "../home-priview/istaknute-akcije/akcije";
import Doniraj from "./doniraj/doniraj";
import PrikupljeneDonacije from "./prikupljene-donacije/prikupljene-donacije";

const AkcijePriview = (props) => {
    const {t, i18n} = props;
    const {REACT_APP_API_URL, REACT_APP_BACKEND} = process.env;
    const navigate = useNavigate();

    const [otvoreneAkcije, setOtvoreneAkcije] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const getOtvoreneAkcije = async () => {
        fetch(REACT_APP_API_URL + "akcije-otvorene?lng=" + i18n.language)
            .then((response) => response.json())
            .then((result) => {
                setOtvoreneAkcije(result.data);
                setLastPage(result.last_page);
            });
    }

    const getMoreAkcija = () => {
        const page = currPage + 1;
        fetch(
            REACT_APP_API_URL +
            "akcije-otvorene?page=" +
            page +
            "&lng=" +
            i18n.language
        )
            .then((response) => response.json())
            .then((result) => {
                setCurrPage(result.current_page);
                setOtvoreneAkcije([...otvoreneAkcije, ...result.data]);
            });
    }

    useEffect(() => {
        getOtvoreneAkcije();
    }, [i18n.language]);

    //   const cardsAkcije = otvoreneAkcije.map((akcija, index) => {
    //     return (
    //       <div className="card col-lg-3 col-md-6 col-sm-12" key={akcija.id}>
    //         <img
    //           src={REACT_APP_BACKEND + "" + akcija.slika}
    //           className="card-img-top w-full"
    //           alt="..."
    //         />
    //         <div className="card-body">
    //           <h5 className="card-title">{akcija.naslov}</h5>
    //           <p
    //             className="card-text hide-text"
    //             style={{ height: "50px" }}
    //             dangerouslySetInnerHTML={{ __html: akcija.sadrzaj }}
    //           ></p>
    //           <div
    //             className="progress"
    //             role="progressbar"
    //             aria-label="Basic example"
    //             aria-valuenow="25"
    //             aria-valuemin="0"
    //             aria-valuemax="100"
    //           >
    //             <div
    //               className="progress-bar"
    //               style={{
    //                 width: `${
    //                   (akcija.prikupljeno_novca * 100) / akcija.potrebno_novca
    //                 }%`,
    //               }}
    //             ></div>
    //           </div>
    //           <div style={{ padding: "5px" }}>
    //             {akcija.prikupljeno_novca.toLocaleString(undefined, {
    //               maximumFractionDigits: 2,
    //             })}{" "}
    //             od{" "}
    //             {akcija.potrebno_novca.toLocaleString(undefined, {
    //               maximumFractionDigits: 2,
    //             })}
    //           </div>
    //           <a
    //             href="#"
    //             className="btn btn-primary"
    //             onClick={() => detailsAction(akcija)}
    //           >
    //             Detail
    //           </a>
    //         </div>
    //       </div>
    //     );
    //   });

    function detailsAction(action) {
        navigate("/akcija/" + action.id + "/" + action.slug);
    }

    return (
        <div className="mt-16">
            <div>
                <h1 className="font-bold text-3xl md:text-4xl text-center">
                    {t('actions_open')}
                </h1>

                <div className="mt-16">
                    <Akcije initialSize={8} akcije={otvoreneAkcije}/>
                </div>
            </div>

            <Doniraj/>
            <PrikupljeneDonacije isSingle="false"/>
        </div>
    );
};

export default withTranslation()(AkcijePriview);
