import {Routes, Route} from "react-router-dom";
import "./i18n";

import Home from "./Routes/home/home.component";
import Navigation from "./Routes/navigation/navigation.component";
import About from "./Routes/about/about.component";
import Contact from "./Routes/contact/contact.component";
import Payment from "./Routes/payment/payment.component";
import Akcije from "./Routes/akcije/akcije.component";
import ZatvoreneAkcije from "./Routes/zatvorene-akcije/zatvorene-akcije";
import Single from "./Routes/single/single.component";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Footer from "./Routes/navigation/footer";
import Donate from "./Routes/donate/donate";
import Doniraj from "./Routes/doniraj/doniraj";
import UsloviKoriscenja from "./Routes/uslovi-koriscenja/uslovi-koriscenja";
import PokreniAkciju from "./Routes/pokreni-akciju/pokreni-akciju";
import PaymentSuccess from "./Routes/payment/payment-success.component";
import PaymentError from "./Routes/payment/payment-error.component";

const App = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Navigation/>}>
                    <Route index element={<Home/>}/>
                    <Route path="home" element={<Home/>}/>
                    <Route path="akcije" element={<Akcije/>}/>
                    <Route path="akcija/:id/:slug" element={<Single/>}/>
                    <Route path="zavrsene-akcije" element={<ZatvoreneAkcije/>}/>
                    <Route path="payment/:akcija/:iznos" element={<Payment/>}/>
                    <Route path="payment-success" element={<PaymentSuccess/>}/>
                    <Route path="payment-error" element={<PaymentError/>}/>
                    <Route path="about" element={<About/>}/>
                    <Route path="contact" element={<Contact/>}/>
                    <Route path="doniraj" element={<Doniraj/>}/>
                    <Route path="uslovi-koriscenja" element={<UsloviKoriscenja/>}/>
                    <Route path="pokreni-akciju" element={<PokreniAkciju/>}/>
                </Route>
            </Routes>
            <Footer/>
        </>
    );
};

export default App;
