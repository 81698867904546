import { Outlet } from "react-router-dom";
import ZavrseneAkcijePreview from "../../components/zavrsene-akcije-preview/zavrsene-akcije-preview";

const Akcije = () => {
    return (
        <div>
            <Outlet />
            <ZavrseneAkcijePreview></ZavrseneAkcijePreview>
        </div>
    );
}

export default ZavrseneAkcijePreview;
