import {Outlet} from "react-router-dom";
import SinglePriview from "../../components/single-priview/single-priview";
import Doniraj from "../../components/akcije-priview/doniraj/doniraj";
import PrikupljeneDonacije from "../../components/akcije-priview/prikupljene-donacije/prikupljene-donacije";
import React from "react";

const Single = () => {
    return (
        <div>
            <Outlet/>
            <SinglePriview/>
        </div>
    );
};

export default Single;
