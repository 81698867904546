import {Link, Outlet, useLocation} from "react-router-dom";
import {Fragment, useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import MNE from "../../locales/me/mne.png"

import Flag from "react-world-flags";

import "./navigation.styles.css";
import {navItems} from "./nav-utils";
import {AnimatePresence, motion} from "framer-motion";

const Navigation = (props) => {
    const {t, i18n} = props;
    const {REACT_APP_API_URL} = process.env;
    const location = useLocation();
    const {pathname} = useLocation();

    const [codes, setCodes] = useState([]);

    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng.code);
    };

    const fetchLanguages = async () => {
        fetch(REACT_APP_API_URL + "languages")
            .then((response) => response.json())
            .then((data) => setCodes(data));
    };

    const flags = codes.map((code, index) => {
        if (code === "me") {
            return <div className="cursor-pointer w-6 h-6 object-cover rounded-full overflow-hidden" key={index}>
                <img src={MNE} alt="MNE" className="w-full h-full object-cover" onClick={() => changeLanguage({code})}/>
            </div>
        }

        return <div className="cursor-pointer w-6 h-6 object-cover rounded-full overflow-hidden">
            <Flag
                key={index}
                code={code === "en" ? "gb" : code}
                className="w-full h-full object-cover"
                onClick={() => changeLanguage({code})}
            />
        </div>
    });

    useEffect(() => {
        fetchLanguages();

    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        setHamburgerOpen(false);
    }, [location]);

    return (
        /* Fragment se koristi da ne bi renderovoao div u stranicu */
        <Fragment>
            <nav className="relative shadow-lg">
                <div
                    className="py-4 bg-white max-w-[1100px] mx-auto flex justify-between lg:grid lg:grid-cols-5 items-center px-5">
                    <div className="lg:block hidden col-span-2">
                        <ul className="flex gap-5">
                            {navItems.left.map((item, index) => (
                                <li key={item.id}>
                                    <Link className="font-semibold text-lg" to={item.link}>
                                        {t(item.label)}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="flex justify-center">
                        <div className="col-span-1 w-[140px] lg:w-[180px]">
                            <Link to="/home" className="flex place-content-center">
                                <img
                                    src="/img/hemiias_logo.svg"
                                    alt="Hemiias"
                                    className="w-full h-full"
                                />
                            </Link>
                        </div>
                    </div>

                    <div className="lg:block hidden col-span-2">
                        <ul className="flex gap-5 justify-end items-center">
                            {navItems.right.map((item, index) => (
                                <Link to={item.link}>
                                    <li
                                        key={item.id}
                                        className={`font-semibold text-lg ${
                                            item.button &&
                                            "bg-primary px-8 py-2 rounded-xl text-white cursor-pointer transition-colors hover:bg-secondary"
                                        }`}
                                    >
                                        {t(item.label)}
                                    </li>
                                </Link>
                            ))}
                            <div className="hidden lg:flex justify-center items-center gap-2">
                                {flags}
                            </div>
                        </ul>
                    </div>

                    <div
                        className="block w-[60px] lg:hidden cursor-pointer"
                        onClick={() => setHamburgerOpen(!hamburgerOpen)}
                    >
                        <img
                            src={
                                hamburgerOpen
                                    ? "/img/icons/close.svg"
                                    : "/img/icons/hamburger-menu.svg"
                            }
                            alt="Hemiiaw"
                            className="w-full h-full"
                        />
                    </div>
                </div>

                <AnimatePresence>
                    {hamburgerOpen && (
                        <motion.div
                            style={{transformOrigin: "top"}}
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{ease: "easeInOut", duration: 0.2}}
                            className="absolute top-full left-0 w-full h-full block z-10 lg:hidden"
                        >
                            <ul className="flex flex-col justify-start pl-8 bg-primary py-8 gap-4 shadow-xl">
                                {navItems.left.concat(navItems.right).map((item, index) => (
                                    <li key={item.id} className="text-2xl font-semibold text-white">
                                        <Link to={item.link}>{t(item.label)}</Link>
                                    </li>
                                ))}

                                <div className="flex gap-4 mt-5">{flags}</div>
                            </ul>
                        </motion.div>
                    )}
                </AnimatePresence>
            </nav>
            <Outlet/>
        </Fragment>
    );
};

export default withTranslation()(Navigation);
