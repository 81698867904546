import React, {useEffect, useState} from "react";
import {Kartica, SMS} from "../icons";
import {withTranslation} from "react-i18next";

const PrikupljeneDonacije = (props) => {
    const {t, i18n, isSingle, akcijaId} = props;

    const [topDonators, setTopDonators] = useState([]);
    const [togetherSum, setTogetherSum] = useState(0);
    const {REACT_APP_API_URL, REACT_APP_BACKEND} = process.env;

    const getTopDonators = async () => {
        if (isSingle === 'true') {
            alert('akcijaId ' + akcijaId);
            fetch(REACT_APP_API_URL + "top-ten/" + akcijaId + "?lng=" + i18n.language)
                .then((response) => response.json())
                .then((result) => {
                    setTopDonators(result);
                });
        } else {
            fetch(REACT_APP_API_URL + "top-ten?lng=" + i18n.language)
                .then((response) => response.json())
                .then((result) => {
                    setTopDonators(result);
                });
            fetch(REACT_APP_API_URL + "together-sum?lng=" + i18n.language)
                .then((response) => response.json())
                .then((result) => {
                    setTogetherSum(result[0].iznos);
                });
        }
    }

    useEffect(() => {
        getTopDonators();
    }, [i18n.language]);

    return (
        <div className="mt-16 pb-10 px-4">
            <div className="max-w-[1100px] mx-auto">

                {isSingle === 'true' ? (
                    <>
                        <h1 className="font-bold text-3xl md:text-4xl text-center">
                            <span>{t('collected_donations')}</span>
                        </h1>
                    </>
                ) : (
                    <>
                        <h1 className="font-bold text-3xl md:text-4xl text-center">
                            <span>{t('collected_donations_together')}</span>
                        </h1>
                        <br/>
                        <h2 className="font-bold text-1xl md:text-2xl text-center">
                            <span>{t('collected_donations_together_sum')}: {togetherSum}€</span>
                        </h2>
                    </>
                )}

                {isSingle === 'true' ? (
                    <>
                        <span></span>
                    </>
                ) : (
                    <>
                        <h3>
                            <span>{t('collected_donations_together_desc')}</span>
                        </h3>
                    </>
                )}

                <div className="grid grid-cols-1 lg:grid-cols-1 gap-8 mt-11">
                    <div style={{display: "none"}}>
                        <div className="flex justify-center items-center gap-2 bg-ternary rounded-lg py-4">
                            <h5 className="font-bold text-xl lg:text-2xl">SMS</h5>
                            <SMS color="#000" size={32}/>
                        </div>

                        <ul className="flex flex-col w-full gap-2 mt-2">
                            {new Array(7).fill(0).map((_, index) => (
                                <li
                                    className="grid grid-cols-5 rounded-md bg-gray-100 gap-4 h-[82px] sm:h-[40px] overflow-hidden"
                                    key={index}
                                >
                                    <div className="col-span-2 pl-6 flex items-center">
                                        <p>02. Mar 2023. 21:41h</p>
                                    </div>
                                    <div className="col-span-2 flex items-center">
                                        <p className="font-bold text-lg">382 67 789 789</p>
                                    </div>
                                    <div className="bg-ternary col-span-1 flex items-center justify-center font-bold">
                                        {index + 1} €
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div>
                        <div className="flex justify-center items-center gap-2 bg-ternary rounded-lg py-4">
                            <h5 className="font-bold text-xl lg:text-2xl">{t('cards_paid')}</h5>
                            <Kartica color="#000" size={32}/>
                        </div>

                        <ul className="flex flex-col w-full gap-2 mt-2">
                            {topDonators.map((donation, index) => (
                                <li
                                    className="grid grid-cols-5 rounded-md gap-2 bg-gray-100 h-[82px] sm:h-[40px] overflow-hidden"
                                    key={index}
                                >
                                    <div className="col-span-2 pl-6 flex items-center text-sm lg:text-base">
                                        <p>{donation.datePayment}h</p>
                                    </div>
                                    <div className="col-span-2 flex items-center">
                                        <p className="font-bold text-base lg:text-lg">{donation.firstName} {donation.lastName}</p>
                                    </div>
                                    <div
                                        className="bg-ternary col-span-1 flex items-center justify-center font-bold text-sm lg:text-base">
                                        {donation.iznos.toLocaleString(undefined, {maximumFractionDigits: 2})} €
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(PrikupljeneDonacije);
