import { Outlet } from "react-router-dom";
import Hero from "../../components/home-priview/hero/hero";
import HomePriview from "../../components/home-priview/home-priview";

const Home = () => {
  return (
    <div>
      <Outlet />
      <HomePriview></HomePriview>
    </div>
  );
};

export default Home;
