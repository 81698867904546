import { Outlet } from "react-router-dom";
import ContactPriview from "../../components/contact-priview/contact-priview";

const Contact = () => {
    return (
        <div>
            <Outlet />
            <ContactPriview></ContactPriview>
        </div>
    )
}

export default Contact;
