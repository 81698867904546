export const navItems = {
    left: [
        {
            id: 1,
            label: "actions",
            link: "/akcije",
        },
        {
            id: 2,
            label: "actions_end",
            link: "/zavrsene-akcije",
        },
        {
            id: 3,
            label: "actions_start",
            link: "/pokreni-akciju",
        },
    ],
    right: [
        {
            id: 4,
            label: "about",
            link: "/about",
        },
        {
            id: 5,
            label: "donate",
            link: "/doniraj",
            button: true,
        },
    ],
};
