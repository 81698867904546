import React, {useEffect, useState} from 'react';
import PlatformeZaDonacije from "../../components/home-priview/platforma-za-donacije/platforma-za-donacije";
import {useTranslation} from "react-i18next";

const UsloviKoriscenja = () => {
    const {t, i18n} = useTranslation();
    const [lng, setLng] = useState("me");
    const [size, setSize] = useState("1rem");
    const [sizeEn, setSizeEn] = useState("1rem");
    const [sizeDe, setSizeDe] = useState("1rem");
    const [sizeEnMe, setSizeEnMe] = useState("22px");

    useEffect(() => {
        setLng(i18n.language);
        console.log("i18n.language ", i18n.language);
        if (i18n.language === "me") {
            setSize("22px");
            setSizeEn("1rem");
        } else if (i18n.language === "en") {
            setSize("1rem");
            setSizeEn("22px");
        } else if (i18n.language === "de") {
            setSize("1rem");
            setSizeEn("1rem");
            setSizeDe("22px");
        } else {
            setSize("1rem");
            setSizeEn("22px");
        }
    }, i18n.language);

    return (
        <>
            <div>
                <div className="mt-16">
                    <div style={{marginTop: "-80px"}}>
                        <div className="bg-ternary mt-20 py-24 px-4">
                            <div className="max-w-[1060px] mx-auto">
                                <div className="grid place-content-center">
                                    <h1 className="font-bold text-3xl md:text-4xl text-center">Uslovi korišćenja</h1>
                                </div>
                                <div className="mt-8">
                                    <p className="text-base font-normal">
                                        <span className="block mb-1">{t('_1')}</span><br/>
                                        <span className="block mb-1">{t('_2')}</span><br/>
                                        <span className="block mb-1">{t('_3')}</span><br/>
                                        <span className="block mb-1">{t('_4')}</span><br/>
                                        <span className="block mb-1">{t('_5')}</span><br/>
                                        <span className="block mb-1"
                                              style={{fontSize: "" + sizeEn + ""}}>{t('_6')}</span><br/>
                                        <span className="block mb-1">{t('_7')}</span><br/>
                                        <span className="block mb-1">{t('_8')} {t('_9')}</span><br/>
                                        <span className="block mb-1"
                                              style={{fontSize: "" + size + ""}}>{t('_10')}</span><br/>
                                        <span className="block mb-1">{t('_11')}</span><br/>
                                        <span className="block mb-1">{t('_12')}</span><br/>
                                        <span className="block mb-1">{t('_13')}</span><br/>
                                        <span className="block mb-1">{t('_14')}</span><br/>
                                        <span className="block mb-1">{t('_15')}</span><br/>
                                        <span className="block mb-1">{t('_16')}</span><br/>
                                        <span className="block mb-1">{t('_17')}</span><br/>
                                        <span className="block mb-1">{t('_18')}</span><br/>
                                        <span className="block mb-1"
                                              style={{fontSize: "" + sizeEn + ""}}>{t('_19')}</span><br/>
                                        <span className="block mb-1">{t('_20')}</span><br/>
                                        <span className="block mb-1">{t('_21')}</span><br/>
                                        <span className="block mb-1"
                                              style={{fontSize: "" + sizeDe + ""}}>{t('_22')}</span><br/>
                                        <span className="block mb-1"
                                              style={{fontSize: "" + size + ""}}>{t('_23')}</span><br/>
                                        <span className="block mb-1">{t('_24')}</span><br/>
                                        <span className="block mb-1"
                                              style={{fontSize: "" + sizeEn + ""}}>{t('_25')}</span><br/>
                                        <span className="block mb-1"
                                              style={{fontSize: "" + sizeDe + ""}}>{t('_26')}</span><br/>
                                        <span className="block mb-1"
                                              style={{fontSize: "" + sizeEn + ""}}>{t('_27')}</span><br/>
                                        <span className="block mb-1"
                                              style={{fontSize: "" + sizeDe + ""}}>{t('_28')}</span><br/>
                                        <span className="block mb-1">{t('_29')}</span><br/>
                                        <span className="block mb-1"
                                              style={{fontSize: "" + sizeDe + ""}}>{t('_30')}</span><br/>
                                        <span className="block mb-1">{t('_31')}</span><br/>
                                        <span className="block mb-1"
                                              style={{fontSize: "" + sizeDe + ""}}>{t('_32')}</span><br/>
                                        <span className="block mb-1"
                                              style={{fontSize: "" + size + ""}}>{t('_33')}</span><br/>
                                        <span className="block mb-1">{t('_34')}</span><br/>
                                        <span className="block mb-1">{t('_35')}</span><br/>
                                        <span className="block mb-1"
                                              style={{fontSize: "" + sizeDe + ""}}>{t('_36')}</span><br/>
                                        <span className="block mb-1">{t('_37')}</span><br/>
                                        <span className="block mb-1">{t('_38')}</span><br/>
                                        <span className="block mb-1"
                                              style={{fontSize: "" + size + ""}}>{t('_39')}</span><br/>
                                        <span className="block mb-1">{t('_40')}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PlatformeZaDonacije/>
        </>
    );
};

export default UsloviKoriscenja;