import React from "react";
import {useTranslation} from "react-i18next";

const ONama = ({pib}) => {

    const {t, i18n} = useTranslation();

    return (
        <div className="bg-ternary mt-20 py-24 px-4">
            <div className="max-w-[700px] mx-auto">
                <div className="grid place-content-center">
                    <div className="w-[50vw] sm:w-[220px]">
                        <img
                            className="w-full h-full"
                            src="img/hemiias_logo.svg"
                            alt="logo"
                        />
                    </div>
                </div>

                <div className="mt-8">
                    <p className="text-base font-normal text-center">
                        <span className="block mb-1">
                          {t('about1')}
                        </span>
                        <br/>
                        <span className="block mb-1">
                          {t('about2')}
                        </span>
                        <br/>
                        <span>
                          {t('about3')}
                        </span>
                    </p>
                </div>
                {pib === 'true' ? (
                    <>
                        <div className="mt-12">
                            <span>
                          {t('about4_1')}
                            </span><br/>
                            <span>
                          {t('about4_2')}
                            </span><br/>
                            <span>
                          {t('about4_3')}
                            </span>
                        </div>
                    </>
                ) : ''}
            </div>
        </div>
    );
};

export default ONama;
