import './payment-priview.styles.css';
import {withTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import {Kartica, Racun, SMS} from "../akcije-priview/icons";

const PaymentSuccessPriview = (props) => {
    const {t, i18n} = props;
    const [referenceId, setReferenceId] = useState('');
    const [amount, setAmount] = useState('');
    const [currency, setCurrency] = useState('');
    const [type, setType] = useState('');
    const [lastFourDigits, setLastFourDigits] = useState('');
    const [dateTime, setDateTime] = useState('');
    const [language_note, setLanguageNote] = useState('');
    const [fingerprint, setFingerprint] = useState('');

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        setReferenceId(params.get("referenceId"));
        setAmount(params.get("amount"));
        setCurrency(params.get("currency"));
        setType(params.get("type"));
        setLastFourDigits(params.get("lastFourDigits"));
        setDateTime(params.get("dateTime"));
        setLanguageNote(params.get("language_note"));
        setFingerprint(params.get("fingerprint"));
    }, []);

    return (
        <div className="max-w-lg mx-auto py-6 px-4">
            <div className="overflow-hidden bg-white rounded-lg shadow-lg">
                <div className="bg-primary flex justify-center items-center py-4">
                    <h5 className="text-white font-medium text-2xl">Success Payment</h5>
                    {/* Assuming Kartica is a custom component */}
                    <Kartica color="#fff" size={38}/>
                </div>
                <div className="px-4 py-6">
                    <div className="overflow-x-auto">
                        <table className="table-fixed">
                            <tbody>
                            <tr>
                                <td className="w-1/4"><b>Reference Id:</b></td>
                                <td className="w-3/4 whitespace-normal">{referenceId}</td>
                            </tr>
                            <tr>
                                <td className="w-1/4"><b>Amount:</b></td>
                                <td className="w-3/4 whitespace-normal">{amount}</td>
                            </tr>
                            <tr>
                                <td className="w-1/4"><b>Currency:</b></td>
                                <td className="w-3/4 whitespace-normal">{currency}</td>
                            </tr>
                            <tr>
                                <td className="w-1/4"><b>Type: </b></td>
                                <td className="w-3/4 whitespace-normal">{type}</td>
                            </tr>
                            <tr>
                                <td className="w-1/4"><b>Last Four Digits: </b></td>
                                <td className="w-3/4 whitespace-normal">{lastFourDigits}</td>
                            </tr>
                            <tr>
                                <td className="w-1/4"><b>Date Time: </b></td>
                                <td className="w-3/4 whitespace-normal">{dateTime}</td>
                            </tr>
                            <tr>
                                <td className="w-1/4"><b>Bank authorization code: </b></td>
                                <td className="w-3/4 whitespace-normal">{fingerprint.substring(0, 6)}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(PaymentSuccessPriview);
