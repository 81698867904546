import React, {useState} from "react";
import ModalImage, {Lightbox} from "react-modal-image";
import {withTranslation} from "react-i18next";

const Gallery = (props) => {
    const {t, i18n} = props;
    const {REACT_APP_API_URL, REACT_APP_BACKEND} = process.env;
    const [modal, setModal] = useState({
        isOpen: false,
        src: "",
    });

    return (
        <>
            <div className="w-full h-full mt-24">
                <div className="max-w-[1100px] mx-auto px-4 xl:px-0">
                    <h2 className="text-center mb-4 text-2xl font-bold">{t('galery')}</h2>
                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                        {props.fotografije.map((photo, index) => (
                            <div className="w-full h-full rounded-lg overflow-hidden cursor-pointer group"
                                 key={index}
                                 onClick={() => setModal({
                                     ...modal,
                                     isOpen: true,
                                     src: "" + REACT_APP_BACKEND + "" + props.galeryPath + "" + photo.naziv + ""
                                 })}>
                                <img
                                    src={REACT_APP_BACKEND + "" + props.galeryPath + "" + photo.naziv}
                                    alt={index}
                                    className="w-full h-full group-hover:scale-110 transition-transform duration-300 ease-in-out"
                                />
                            </div>
                        ))}
                    </div>

                    {props.videoLinkovi.map((linkVideo, index) => (
                        <div className="flex justify-center mt-8" key={linkVideo.id}>
                            <iframe
                                className="w-full aspect-video rounded-lg"
                                src={`https://www.youtube.com/embed/${linkVideo.url.substring(linkVideo.url.lastIndexOf("=") + 1)}`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                            ></iframe>
                        </div>
                    ))}
                </div>
            </div>

            {modal.isOpen && (
                <Lightbox
                    medium={modal.src}
                    large={modal.src}
                    small={modal.src}
                    hideDownload
                    hideZoom
                    onClose={() => setModal({...modal, isOpen: false, src: ""})}
                />
            )}
        </>

    );
};

export default withTranslation()(Gallery);
