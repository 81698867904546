import { Outlet } from "react-router-dom";
import AkcijePriview from "../../components/akcije-priview/akcije-priview";

const Akcije = () => {
    return (
      <div>
          <Outlet />
          <AkcijePriview></AkcijePriview>
      </div>
    );
}

export default Akcije;
