import './payment-priview.styles.css';
import {withTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import {Kartica, Racun, SMS} from "../akcije-priview/icons";

const PaymentErrorPriview = (props) => {
    const {t, i18n} = props;
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        setErrorMsg(params.get("errorMsg"));
    }, []);

    return (
        <div style={{height: "380px"}}>
            <div style={{paddingTop: "1rem", paddingBottom: "1rem"}}>
                <div className="max-w-[1100px] mx-auto px-4">
                    <div className="mt-12 swiper-autoheight" style={{height: "100px"}}>
                        <div className="grid grid-cols-1 sm:grid-cols-12 lg:grid-cols-1 gap-8"
                             style={{height: "333px"}}>
                            <div className="h-[320px] rounded-2xl bg-white shadow-lg overflow-hidden"
                                 style={{height: "100%"}}>
                                <div className="bg-primary flex justify-center gap-2 py-4">
                                    <h5 className="text-white font-medium text-2xl text-center text-white">
                                        Error Payment
                                    </h5>
                                    <Kartica color="#fff" size={38}/>
                                </div>
                                <div className="flex justify-center items-center flex-col pt-10 pb-8 px-4">
                                    <span className="flex h-full gap-2">
                                        <table>
                                            <tr>
                                                <td><b>Error: </b></td>
                                                <td style={{paddingLeft: "10px"}}>{errorMsg}</td>
                                            </tr>
                                        </table>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(PaymentErrorPriview);
