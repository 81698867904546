import {Outlet} from "react-router-dom";
import PaymentErrorPriview from "../../components/payment-priview/payment-error-priview";

const PaymentError = () => {
    return (
        <div>
            <Outlet/>
            <PaymentErrorPriview></PaymentErrorPriview>
        </div>
    )
}

export default PaymentError;
