import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "./hero.css";
import {withTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const Hero = (props) => {
    const {t, i18n} = props;
    const {REACT_APP_API_URL, REACT_APP_BACKEND} = process.env;
    const [slideShow, setSlideShow] = useState([]);
    const navigate = useNavigate();

    const getSlideShow = async () => {
        fetch(REACT_APP_API_URL + "akcije-slider?lng=" + i18n.language)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                setSlideShow(result);
            });
    };

    useEffect(() => {
        getSlideShow();
    }, [i18n.language]);

    function detailsAction(action) {
        navigate("/akcija/" + action.id + "/" + action.slug);
    }

    return (
        <div className="relative">
            <Swiper slidesPerView="auto" navigation grabCursor>
                {slideShow.map((akcijaCarusel, index) => (
                    <SwiperSlide key={akcijaCarusel.id}>
                        <div className="relative h-full">
                            <div className="absolute inset-0 w-full lg:w-1/2 z-30 overflow-hidden">
                                <div
                                    className="relative lg:absolute lg:right-10 flex justify-center items-center sm:items-start flex-col h-full w-[74%] lg:w-[80%] mx-auto lg:mx-0 xl:w-[490px] z-10 text-center sm:text-left">
                                    <h3 className="text-white text-2xl font-bold mb-4">
                                        {akcijaCarusel.naslov}
                                    </h3>
                                    <p
                                        className="text-white"
                                        dangerouslySetInnerHTML={{__html: akcijaCarusel.sadrzaj}}
                                    ></p>

                                    <button onClick={() => detailsAction(akcijaCarusel)}
                                            className="bg-primary px-8 py-2 mt-6 text-white rounded-xl font-bold hover:bg-secondary">
                                        {t('look_single_action')}
                                    </button>
                                </div>
                                <div className="absolute inset-0 bg-black opacity-50"></div>
                            </div>

                            <img
                                src={REACT_APP_BACKEND + "" + akcijaCarusel.slika}
                                className="w-full h-full object-cover"
                            />
                        </div>
                    </SwiperSlide>
                ))}
                {slideShow && slideShow.length < 1 && (
                    <SwiperSlide key="nema-akcija">
                        <div className="relative h-full">
                            <div className="absolute inset-0 w-full lg:w-1/2 z-30 overflow-hidden">
                                <div
                                    className="relative lg:absolute lg:right-10 flex justify-center items-center sm:items-start flex-col h-full w-[74%] lg:w-[80%] mx-auto lg:mx-0 xl:w-[490px] z-10 text-center sm:text-left">
                                    <h3 className="text-white text-2xl font-bold mb-4">
                                        Naše je samo ono što damo
                                    </h3>
                                    <p
                                        className="text-white"
                                    >
                                        Humanitarna fondacija Hemiias svojim djelovanjem pomaže ugroženim građanima na
                                        teritoriji Crne Gore, a i šire.
                                    </p>
                                </div>
                                <div className="absolute inset-0 bg-black opacity-50"></div>
                            </div>

                            <img
                                src="/img/nema-akcija.png"
                                className="w-full h-full object-cover"
                            />
                        </div>
                    </SwiperSlide>
                )}
            </Swiper>
        </div>
    );
};

export default withTranslation()(Hero);
