import React, {useEffect, useState} from "react";
import IstaknuteCard from "./istaknute-card";
import {withTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import ShareModal from "./share-modal";
import {AnimatePresence} from "framer-motion";

const Akcije = (props) => {
    const {t, i18n} = props;
    const {REACT_APP_API_URL, REACT_APP_BACKEND} = process.env;
    const [izdvojeneAkcije, setIzdvojeneAkcije] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [showModal, setShowModal] = useState({
        open: false,
        link: "",
        akcija: {}
    });

    const navigate = useNavigate();

    useEffect(() => {
        fetch(REACT_APP_API_URL + "akcije-otvorene?lng=" + i18n.language)
            .then((response) => response.json())
            .then((result) => {
                console.log(result.data);
                setIzdvojeneAkcije(result.data);
                setLastPage(result.last_page);
            });
    }, [i18n.language]);

    const progress = "absolute top-0 left-0 h-full";

    const akcije = async () => {
        return izdvojeneAkcije.map((akcija, index) => (
            <div key={akcija.id}>
                <div className="shadow-xl rounded-xl overflow-hidden">
                    <div className="h-full">
                        <div className="h-[200px]">
                            <img
                                src={REACT_APP_BACKEND + "" + akcija.slika}
                                alt="test"
                                className="w-full h-full object-cover"
                            />
                        </div>

                        <div className="mt-10 px-4 pb-5">
                            <div>
                                <h4 className="text-2xl font-bold text-center mb-4">
                                    {akcija.naslov}
                                </h4>
                                <p
                                    className="text-center text-gray-500"
                                    dangerouslySetInnerHTML={{__html: akcija.sadrzaj}}
                                ></p>

                                <div className="mt-4 flex justify-center items-center flex-wrap">
                                    <button
                                        className="py-2 w-full mx-6 bg-primary rounded-xl text-white font-semibold hover:bg-secondary">
                                        Doniraj
                                    </button>
                                </div>
                            </div>

                            <div className="mt-8 flex justify-between items-center gap-6">
                                <div className="w-full">
                                    <div className="relative w-full h-[20px] bg-gray-200">
                                        <div
                                            className={
                                                progress +
                                                " w-[" +
                                                Math.round(
                                                    (akcija.prikupljeno_novca * 100) /
                                                    akcija.potrebno_novca
                                                ) +
                                                "%] bg-primary"
                                            }
                                        ></div>
                                    </div>
                                    <p className="mt-2">
                    <span className="font-bold">
                      {akcija.prikupljeno_novca.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                      })} €
                    </span>{" "}
                                        od{" "}
                                        {akcija.potrebno_novca.toLocaleString(undefined, {
                                            maximumFractionDigits: 2,
                                        })} €
                                    </p>
                                </div>

                                <div className="flex justify-center flex-col items-center cursor-pointer">
                                    <div className="w-[26px] shrink-0">
                                        <img
                                            src="/img/icons/share.svg"
                                            alt="share"
                                            className="w-full h-full"
                                        />
                                    </div>
                                    <span className="text-sm text-center">Podijeli</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ));
    }

    function detailsAction(action) {
        navigate("/akcija/" + action.id + "/" + action.slug);
    }

    return (
        <>
            <div className="px-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 max-w-[1100px] mx-auto">
                    {props.akcije.map((akcija, index) => (
                        <div key={index}>
                            <div className="shadow-xl rounded-xl overflow-hidden h-full">
                                <div>
                                    <Link
                                        to={`/akcija/${akcija.id}/${akcija.slug}`}
                                        className="h-[200px]"
                                    >
                                        <img
                                            src={REACT_APP_BACKEND + "" + akcija.slika}
                                            alt="test"
                                            className="w-full h-full object-cover"
                                        />
                                    </Link>

                                    <div className="mt-8 px-4 pb-5">
                                        <div>
                                            <Link
                                                to={`/akcija/${akcija.id}/${akcija.slug}`}
                                                className="text-xl font-bold text-center mb-4"
                                            >
                                                <span className="text-ellipsis h-[68px] overflow-hidden block">
                                                  {akcija.naslov}
                                                </span>
                                            </Link>
                                            <p
                                                className="text-center text-gray-500"
                                                dangerouslySetInnerHTML={{__html: akcija.sadrzaj}}
                                            ></p>

                                            <div className="mt-4 flex justify-center items-center flex-wrap">
                                                <button
                                                    onClick={() => detailsAction(akcija)}
                                                    className="py-2 w-full mx-6 bg-primary rounded-xl text-white font-semibold hover:bg-secondary"
                                                >
                                                    {t('donate')}
                                                </button>
                                            </div>
                                        </div>

                                        <div className="mt-8 flex justify-between items-center gap-6">
                                            <div className="w-full">
                                                <div className="relative w-full h-[20px] bg-gray-200">
                                                    <div
                                                        className="absolute top-0 left-0 h-full bg-primary"
                                                        style={{
                                                            width: `${Math.round(
                                                                (akcija.prikupljeno_novca * 100) /
                                                                akcija.potrebno_novca
                                                            )}%`,
                                                        }}
                                                    ></div>
                                                </div>
                                                <p className="mt-2">
                          <span className="font-bold">
                            {akcija.prikupljeno_novca.toLocaleString(
                                undefined,
                                {
                                    maximumFractionDigits: 2,
                                }
                            )} €
                          </span>{" "}
                                                    od{" "}
                                                    {akcija.potrebno_novca.toLocaleString(undefined, {
                                                        maximumFractionDigits: 2,
                                                    })} €
                                                </p>
                                            </div>

                                            <div
                                                className="flex justify-center flex-col items-center cursor-pointer"
                                                onClick={() =>
                                                    setShowModal({open: true, link: "test123", akcija: akcija})
                                                }
                                            >
                                                <div className="w-[26px] shrink-0">
                                                    <img
                                                        src="/img/icons/share.svg"
                                                        alt="share"
                                                        className="w-full h-full"
                                                    />
                                                </div>
                                                <span className="text-sm text-center">Podijeli</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="text-center mt-14">
                    {props.home === 'true' ? (
                        <button
                            onClick={() => navigate("/akcije")}
                            className="px-12 py-2 bg-primary hover:bg-secondary text-white font-bold rounded-lg"
                        >
                            {t('look_other_actions')}
                        </button>
                    ) : ''
                    }
                    {currPage !== lastPage && props.home != 'true' ? (
                        <button
                            // onClick={() => getMoreAkcija()}
                            className="px-12 py-2 bg-primary hover:bg-secondary text-white font-bold rounded-lg"
                        >
                            Učitajte još akcija
                        </button>
                    ) : props.home !== 'true' ? (
                        <p className="px-12 py-2 text-black font-bold rounded-lg">
                            <span style={{display: "none"}}>Nema više akcija</span>
                        </p>
                    ) : ''}
                </div>
            </div>

            <AnimatePresence>
                {showModal.open && (
                    <ShareModal showModal={showModal} setShowModal={setShowModal} akcija={showModal.akcija}/>
                )}
            </AnimatePresence>
        </>
    );
};

export default withTranslation()(Akcije);
