import './payment-priview.styles.css';
import {withTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import {Kartica, Racun, SMS} from "../akcije-priview/icons";
import {useParams} from "react-router-dom";
import Select from 'react-select';
import {countries} from 'countries-list';

const PaymentPriview = (props) => {
    const {t, i18n} = props;
    const {akcija, iznos} = useParams();
    const numberCardRef = useRef(null);
    const transactionTokenRef = useRef(null);
    const cvvErrorRef = useRef(null);
    const numErrorRef = useRef(null);
    const yearErrorRef = useRef(null);
    const cvvRef = useRef(null);
    const [years, setYears] = useState([]);
    const [transactionToken, setTransactionToken] = useState('');
    const [errors, setErrors] = useState({});
    const [validCvv, setValidCvv] = useState(false);
    const [validNumber, setValidNumber] = useState(false);

    const [selectedCountry, setSelectedCountry] = useState(null);

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
    };

    const countryOptions = Object.entries(countries).map(([code, name]) => ({
        value: code,
        label: name.name,
    }));

    useEffect(() => {
        const yeatArr = [];
        for (let i = new Date().getFullYear(); i <= new Date().getFullYear() + 10; i++) {
            yeatArr.push(i);
        }
        setYears(yeatArr);
    }, []);

    useEffect(() => {
        console.log('...');
    }, [years]);

    useEffect(() => {
        console.log('...');
    }, [transactionToken]);

    useEffect(() => {
        console.log('...');
    }, [errors]);

    const handleInputChange = (event: React.ChangeEvent<HTMLFormElement>) => {
        const {name, value} = event.target;

        if (name === 'first_name') {
            const numberCardElement = numberCardRef.current.querySelector('p[id="first_name_error"]');
            numberCardElement.textContent = value ? '' : t('payment_form_required');
        }
        if (name === 'last_name') {
            const numberCardElement = numberCardRef.current.querySelector('p[id="last_name_error"]');
            numberCardElement.textContent = value ? '' : t('payment_form_required');
        }
        if (name === 'phone') {
            const numberCardElement = numberCardRef.current.querySelector('p[id="phone_error"]');
            numberCardElement.textContent = value ? '' : t('payment_form_required');
        }
        if (name === 'email') {
            const numberCardElement = numberCardRef.current.querySelector('p[id="email_error"]');
            numberCardElement.textContent = value ? '' : t('payment_form_required');
        }
        if (name === 'terms') {
            const numberCardElement = numberCardRef.current.querySelector('p[id="terms_error"]');
            numberCardElement.textContent = value ? '' : t('payment_form_required_terms');
        }

        // setErrors((prevState) => ({...prevState, [name]: value ? '' : 'Polje je obavezno!'}));
    };

    const validateForm = () => {
        const newErrors = {};
        let errorCount = 0;

        if (!numberCardRef.current.querySelector('input[name="first_name"]').value) {
            newErrors.first_name = t('payment_form_required');
            const numberCardElement = numberCardRef.current.querySelector('p[id="first_name_error"]');
            numberCardElement.textContent = t('payment_form_required');
            errorCount++;
        }
        if (!numberCardRef.current.querySelector('input[name="last_name"]').value) {
            newErrors.last_name = t('payment_form_required');
            const numberCardElement = numberCardRef.current.querySelector('p[id="last_name_error"]');
            numberCardElement.textContent = t('payment_form_required');
            errorCount++;
        }
        if (!numberCardRef.current.querySelector('input[name="phone"]').value) {
            newErrors.phone = t('payment_form_required');
            const numberCardElement = numberCardRef.current.querySelector('p[id="phone_error"]');
            numberCardElement.textContent = t('payment_form_required');
            errorCount++;
        }
        if (!numberCardRef.current.querySelector('input[name="email"]').value) {
            newErrors.email = t('payment_form_required');
            const numberCardElement = numberCardRef.current.querySelector('p[id="email_error"]');
            numberCardElement.textContent = t('payment_form_required');
            errorCount++;
        }
        if (!numberCardRef.current.querySelector('input[name="terms"]').checked) {
            newErrors.terms = t('payment_form_required_terms');
            const numberCardElement = numberCardRef.current.querySelector('p[id="terms_error"]');
            numberCardElement.textContent = t('payment_form_required_terms');
            errorCount++;
        }

        // setErrors(newErrors);
        // Return true if there are no errors, false otherwise
        // return Object.keys(newErrors).length === 0;
        return errorCount === 0;
    }

    var payment = new window.PaymentJs();
    // PcvSl4inVoDMvUrauZvm , FX2zBNtEWQVqEEr7FF7n
    // Payment integration key
    payment.init('Dsrk1YGlMtT19Gz8BWgx', 'number_div', 'cvv_div', function (payment) {
        // console.log(payment);
        payment.setNumberPlaceholder(''); // Card number
        payment.setNumberStyle({
            'border': '0px solid white',
            'width': '100%',
            'height': '100%',
            'font-family': 'inherit',
            'font-size': '100%',
            'font-weight': 'inherit',
            'line-height': 'inherit',
            'color': 'inherit',
            'margin': '0',
            'padding': '10px',
        });
        payment.setCvvPlaceholder(''); // CVV
        payment.setCvvStyle({
            'border': '0px solid white',
            'width': '100%',
            'height': '100%',
            'font-family': 'inherit',
            'font-size': '100%',
            'font-weight': 'inherit',
            'line-height': 'inherit',
            'color': 'inherit',
            'margin': '0',
            'padding': '10',
        });
        payment.numberOn('input', function (data) {
            const numberCardElement = numberCardRef.current.querySelector('div[id="number_div"]');
            const cvvElement = numberCardRef.current.querySelector('div[id="cvv_div"]');
            if (data.validNumber) {
                numberCardElement.style.border = '2px solid green';
                numErrorRef.current.querySelector('p[id="num_error"]').textContent = "";
            } else {
                numberCardElement.style.border = '2px solid red';
                numErrorRef.current.querySelector('p[id="num_error"]').textContent = "Invalid!";
            }
            /*if (data.validCvv) {
                cvvElement.style.border = '2px solid green';
                numberCardRef.current.querySelector('p[id="cvv_error"]').textContent = "";
            } else {
                cvvElement.style.border = '2px solid red';
            }*/
        });
        payment.cvvOn('input', function (data) {
            const cvvElement = numberCardRef.current.querySelector('div[id="cvv_div"]');
            if (data.validCvv) {
                cvvElement.style.border = '2px solid green';
                cvvErrorRef.current.querySelector('p[id="cvv_error"]').textContent = "";
            } else {
                cvvElement.style.border = '2px solid red';
                cvvErrorRef.current.querySelector('p[id="cvv_error"]').textContent = "Invalid!";
            }
        });
    });

    const interceptSubmit = () => {
        // e.preventDefault();
        if (!validateForm()) {
            return;
        }
        // Hemiias1688!
        var data = {
            // card_holder: numberCardRef.current.querySelector('input[name="card_holder"]').value,
            first_name: numberCardRef.current.querySelector('input[name="first_name"]').value,
            last_name: numberCardRef.current.querySelector('input[name="last_name"]').value,
            month: numberCardRef.current.querySelector('select[name="exp_month"]').value,
            year: numberCardRef.current.querySelector('select[name="exp_year"]').value,
            shipping_country: numberCardRef.current.querySelector('input[name="country"]').value,
            shipping_city: numberCardRef.current.querySelector('input[name="city"]').value,
            shipping_postcode: numberCardRef.current.querySelector('input[name="zipcode"]').value,
            shipping_phone: numberCardRef.current.querySelector('input[name="phone"]').value,
            email: numberCardRef.current.querySelector('input[name="email"]').value,
            adress: numberCardRef.current.querySelector('input[name="adress"]').value
        };

        payment.tokenize(
            data, //additional data, MUST include card_holder (or first_name & last_name), month and year
            function (token, cardData) { //success callback function
                // $('#transaction_token').val(token); //store the transaction token
                // numberCardRef.current.querySelector('input[id="transaction_token"]').value = token;
                // setTransactionToken(token);
                transactionTokenRef.current.value = token;
                // $('#payment_form').get(0).submit(); //submit the form
                numberCardRef.current.querySelector('form[id="payment_form"]').submit();
            },
            function (errorsi) {
                //error callback function
                // alert('Errors occurred');
                // render error information here
                let year = [];
                let num = [];
                let cvv = [];
                errorsi.map(error => {
                    if (error.attribute === 'year') {
                        year.push({'year': error.message});
                    }
                    if (error.attribute === 'number') {
                        num.push({'number': error.message});
                    }
                    if (error.attribute === 'cvv') {
                        cvv.push({'cvv': error.message});
                    }
                });
                if (year.length > 0) {
                    yearErrorRef.current.querySelector('p[id="year_error"]').textContent = "Invalid!";
                } else {
                    yearErrorRef.current.querySelector('p[id="year_error"]').textContent = "";
                }

                const numberCardElement = numberCardRef.current.querySelector('div[id="number_div"]');
                const cvvElement = numberCardRef.current.querySelector('div[id="cvv_div"]');
                if (num.length > 0) {
                    numErrorRef.current.querySelector('p[id="num_error"]').textContent = "Invalid!";
                    numberCardElement.style.border = '2px solid red';
                } else {
                    numErrorRef.current.querySelector('p[id="num_error"]').textContent = "";
                    numberCardElement.style.border = '2px solid green';
                }
                if (cvv.length > 0) {
                    cvvErrorRef.current.querySelector('p[id="cvv_error"]').textContent = "Invalid!";
                    cvvElement.style.border = '2px solid red';
                } else {
                    cvvErrorRef.current.querySelector('p[id="cvv_error"]').textContent = "";
                    cvvElement.style.border = '2px solid green';
                }
                setErrors({
                    ...errors,
                    'year': year.length > 0 ? year[0].year : '',
                    'number': num.length > 0 ? num[0].number : '',
                    'cvv': cvv.length > 0 ? cvv[0].cvv : ''
                });
            }
        );
    }

    return (
        <div>
            <div className="mt-16" ref={numberCardRef}>
                <h1 className="font-bold text-xl md:text-4xl text-center" style={{marginBottom: "33px"}}>
                    {t('payment_form_amount')} {iznos} €
                    {/*<span style={{display: 'inline-grid'}}
                          className="border-2 w-[58px] rounded-2xl aspect-square place-content-center text-lg font-bold">
                                            10.00 €
                                        </span>*/}
                </h1>

                <form id="payment_form" method="GET" action={`https://app.hemiias.me/api/payment/${akcija}`}
                      onSubmit={interceptSubmit}
                      className="mt-12">
                    <div className="sm:grid sm:grid-cols-1 md:grid-cols-2 gap-8 max-w-[1100px] mx-auto px-6">
                        <div className="w-full mb-6 md:mb-0 col-span-2">
                            <h1 className="w-full mb-6 md:mb-0 col-span-2 font-bold lg:text-left text-xl md:text-2xl">{t('payment_form_donator_details')}</h1>
                        </div>
                        <div className="w-full mb-6 md:mb-0">
                            <div className="sm:grid sm:grid-cols-1 md:grid-cols-2 gap-4 max-w-[1100px] mx-auto">
                                <input type="hidden" ref={transactionTokenRef} name="transaction_token"
                                       id="transaction_token"/>

                                <input name="iznos" value={iznos} type="hidden"/>

                                <div className="w-full mb-6 md:mb-0">
                                    <label
                                        className="block uppercase lg:text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="first_name"
                                    >
                                        {t('payment_form_first_name')}<span style={{color: "red"}}>*</span>
                                    </label>
                                    <input
                                        className={`appearance-none block w-full bg-neutral-100 text-gray-700 border  
                            rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                                        id="first_name"
                                        name="first_name"
                                        type="text"
                                        onChange={handleInputChange}
                                    />
                                    {errors.first_name && (
                                        <p className="text-red text-xs italic">{errors.first_name}</p>
                                    )}
                                    <p id="first_name_error" className="text-red text-xs italic"></p>
                                </div>

                                <div className="w-full mb-6 md:mb-0">
                                    <label
                                        className="block uppercase lg:text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="last_name"
                                    >
                                        {t('payment_form_last_name')}<span style={{color: "red"}}>*</span>
                                    </label>
                                    <input
                                        className={`appearance-none block w-full bg-neutral-100 text-gray-700 border  
                            rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                                        id="last_name"
                                        name="last_name"
                                        type="text"
                                        onChange={handleInputChange}
                                    />
                                    {errors.last_name && (
                                        <p className="text-red text-xs italic">{errors.last_name}</p>
                                    )}
                                    <p id="last_name_error" className="text-red text-xs italic"></p>
                                </div>

                                <div className="w-full mb-6 md:mb-0">
                                    <label
                                        className="block uppercase lg:text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="adress"
                                    >
                                        {t('payment_form_adress')}
                                    </label>
                                    <input
                                        className={`appearance-none block w-full bg-neutral-100 text-gray-700 border  
                            rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                                        id="adress"
                                        name="adress"
                                        type="text"
                                    />
                                </div>

                                <div className="w-full mb-6 md:mb-0">
                                    <label
                                        className="block uppercase lg:text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="city"
                                    >
                                        {t('payment_form_city')}
                                    </label>
                                    <input
                                        className={`appearance-none block w-full bg-neutral-100 text-gray-700 border  
                            rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                                        id="city"
                                        name="city"
                                        type="text"
                                    />
                                </div>

                                <div className="w-full mb-6 md:mb-0">
                                    <label
                                        className="block uppercase lg:text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="zipcode"
                                    >
                                        {t('payment_form_zpp_code')}
                                    </label>
                                    <input
                                        className={`appearance-none block w-full bg-neutral-100 text-gray-700 border  
                            rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                                        id="zipcode"
                                        name="zipcode"
                                        type="text"
                                    />
                                </div>

                                <div className="w-full mb-6 md:mb-0">
                                    <label
                                        className="block uppercase lg:text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="country"
                                    >
                                        {t('payment_form_country')}
                                    </label>
                                    {/*<input
                                        className={`appearance-none block w-full bg-neutral-100 text-gray-700 border  
                            rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                                        id="country"
                                        name="country"
                                        type="text"
                                    />*/}
                                    <Select
                                        options={countryOptions}
                                        value={selectedCountry}
                                        onChange={handleCountryChange}
                                        placeholder="Select a country"
                                        name="country"
                                        id="country"
                                        isSearchable
                                    />

                                </div>

                                <div className="w-full mb-6 md:mb-0">
                                    <label
                                        className="block uppercase lg:text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="phone"
                                    >
                                        {t('payment_form_phone')}<span style={{color: "red"}}>*</span>
                                    </label>
                                    <input
                                        className={`appearance-none block w-full bg-neutral-100 text-gray-700 border  
                            rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                                        id="phone"
                                        name="phone"
                                        type="text"
                                        onChange={handleInputChange}
                                    />
                                    {errors.phone && (
                                        <p className="text-red text-xs italic">{errors.phone}</p>
                                    )}
                                    <p id="phone_error" className="text-red text-xs italic"></p>
                                </div>

                                <div className="w-full mb-6 md:mb-0">
                                    <label
                                        className="block uppercase lg:text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="email"
                                    >
                                        Email<span style={{color: "red"}}>*</span>
                                    </label>
                                    <input
                                        className={`appearance-none block w-full bg-neutral-100 text-gray-700 border  
                            rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                                        id="email"
                                        name="email"
                                        type="text"
                                        onChange={handleInputChange}
                                    />
                                    {errors.email && (
                                        <p className="text-red text-xs italic">{errors.email}</p>
                                    )}
                                    <p id="email_error" className="text-red text-xs italic"></p>
                                </div>
                                <div className="w-full mb-6 md:mb-0">
                                    <div className="mt-4">
                                        <input
                                            type="checkbox"
                                            name="terms"
                                            id="terms"
                                            onChange={handleInputChange}
                                        />
                                        <label className="ml-1">{t('accept_terms')}
                                            <a href="/uslovi-koriscenja" target="_blank"
                                               style={{color: "blue"}}>{t('accept_terms2')}</a>
                                        </label>
                                        <p id="terms_error" className="text-red text-xs italic"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full mb-6 md:mb-0">
                            <div className="sm:grid sm:grid-cols-1 md:grid-cols-2 gap-4 max-w-[1100px] mx-auto">
                                {/*<div className="w-full mb-6 md:mb-0 col-span-2" ref={cvvRef}>
                                    <label
                                        className="block uppercase lg:text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="card_holder"
                                    >
                                        Card holder<span style={{color: "red"}}>*</span>
                                    </label>
                                    <input
                                        className={`appearance-none block w-full bg-neutral-100 text-gray-700 border  
                            rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                                        id="card_holder"
                                        name="card_holder"
                                        type="text"
                                    />
                                </div>*/}

                                <div className="w-full mb-6 md:mb-0" ref={numErrorRef}>
                                    <label
                                        className="block uppercase lg:text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="number_div"
                                    >
                                        {t('payment_form_card_number')}<span style={{color: "red"}}>*</span>
                                    </label>
                                    <div id="number_div" className="appearance-none block w-full bg-neutral-100 text-gray-700 border
                            rounded mb-3 leading-tight focus:outline-none focus:bg-white"
                                         style={{
                                             height: '44px',
                                             borderRadius: '0.25rem'
                                         }}></div>
                                    {/*{errors.number && (
                                        <p className="text-red text-xs italic">{errors.number}</p>
                                    )}*/}
                                    <p className="text-red text-xs italic" id="num_error"></p>
                                </div>

                                <div className="w-full mb-6 md:mb-0" ref={cvvErrorRef}>
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="cvv_div"
                                    >
                                        {t('payment_form_cvv')}<span style={{color: "red"}}>*</span>
                                    </label>
                                    <div id="cvv_div" className="appearance-none block w-full bg-neutral-100 text-gray-700 border
                            rounded mb-3 leading-tight focus:outline-none focus:bg-white" style={{
                                        height: '44px',
                                        borderRadius: '0.25rem'
                                    }}></div>
                                    {/*{errors.cvv && (
                                        <p className="text-red text-xs italic" id="cvv_error">{errors.cvv}</p>
                                    )}*/}
                                    <p className="text-red text-xs italic" id="cvv_error"></p>
                                </div>

                                <div className="w-full mb-6 md:mb-0" ref={yearErrorRef}>
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="exp_month"
                                    >
                                        {t('payment_form_month')}<span style={{color: "red"}}>*</span>
                                    </label>
                                    <select
                                        className={`block appearance-none w-full bg-neutral-100 border text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                                        id="exp_month"
                                        name="exp_month"
                                    >
                                        <option value="01">01</option>
                                        <option value="02">02</option>
                                        <option value="03">03</option>
                                        <option value="04">04</option>
                                        <option value="05">05</option>
                                        <option value="06">06</option>
                                        <option value="07">07</option>
                                        <option value="08">08</option>
                                        <option value="09">09</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                    {/*{errors.year && (
                                        <p className="text-red text-xs italic">{errors.year}</p>
                                    )}*/}
                                    <p className="text-red text-xs italic" id="year_error"></p>
                                </div>

                                <div className="w-full mb-6 md:mb-0">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="exp_year"
                                    >
                                        {t('payment_form_year')}<span style={{color: "red"}}>*</span>
                                    </label>
                                    <select
                                        className={`block appearance-none w-full bg-neutral-100 border text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                                        id="exp_year"
                                        name="exp_year"
                                    >
                                        {years.map(year => (
                                            <option key={year} value={year}>{year}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="w-full mb-6 md:mb-0">
                                    <div className="flex items-center gap-2 font-medium">
                                        <div
                                            className={`cursor-pointer w-4 aspect-square grid place-content-center hover:scale-90 transition-transform`}
                                            /*style={{borderColor: "#0047AB"}}*/
                                        >
                                            {/*<FaCcVisa color="0047AB" size="20"/>*/}
                                            <img
                                                src="/img/cards/visa.svg" alt=""/>
                                        </div>
                                        <div
                                            className={`cursor-pointer w-4 aspect-square grid place-content-center hover:scale-90 transition-transform`}
                                        >
                                            <img src="/img/cards/master.svg" alt=""/>
                                        </div>
                                        <div
                                            className={`cursor-pointer w-4 aspect-square grid place-content-center hover:scale-90 transition-transform`}
                                        >
                                            <img src="/img/cards/maestro.svg" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full mb-6 md:mb-0 col-span-2">
                            <button
                                type="button"
                                onClick={interceptSubmit}
                                className="font-semibold text-lg bg-primary px-8 py-2 rounded-xl text-white cursor-pointer transition-colors hover:bg-secondary"
                            >
                                {t('payment_form_pay')}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {/*
            <div className="bg-ternary py-20 mt-20">
                <div className="max-w-[1100px] mx-auto px-4">
                    <h3 className="font-bold text-4xl text-center">Doniraj</h3>

                    <div className="mt-4 max-w-[80%] text-center mx-auto">
                        <p className="text-center text-gray-600 font-medium">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        </p>
                    </div>

                    <div className="mt-12 swiper-autoheight" style={{height: "1000px"}}>
                        <div className="grid grid-cols-1 sm:grid-cols-12 lg:grid-cols-1 gap-8"
                             style={{height: "100%"}}>
                            <div className="h-[320px] rounded-2xl bg-white shadow-lg overflow-hidden"
                                 style={{height: "100%"}}>
                                <div className="bg-primary flex justify-center gap-2 py-4">
                                    <h5 className="text-white font-medium text-2xl text-center text-white">
                                        Uplati karticom
                                    </h5>
                                    <Kartica color="#fff" size={38}/>
                                </div>
                                <div className="flex justify-center items-center flex-col pt-10 pb-8 px-4"
                                     ref={numberCardRef}>
                                    <form id="payment_form" onSubmit={interceptSubmit}>
                                        <input type="hidden" name="transaction_token" id="transaction_token"/>
                                        <div className="flex h-full gap-2">
                                            <input
                                                type="text"
                                                placeholder="Iznos"
                                                className="px-2 border-2 rounded-2xl"
                                            />
                                            <span
                                                className="border-2 w-[30px] rounded-2xl aspect-square grid place-content-center text-lg font-bold">
                                            €
                                        </span>
                                        </div>
                                        <div className="flex h-full gap-2" ref={cvvRef}>
                                            <input type="text" id="card_holder" name="card_holder"
                                                   placeholder="Card holder"
                                                   className="px-2 border-2 rounded-2xl"/>
                                        </div>
                                        <div className="flex h-full gap-2">
                                            <div id="number_div" className="px-2 border-2 rounded-2xl"
                                                 style={{
                                                     height: '28px',
                                                     width: '198px',
                                                     border: '1px solid red'
                                                 }}></div>
                                        </div>
                                        <div className="flex h-full gap-2">
                                            <div id="cvv_div" style={{
                                                height: '28px',
                                                width: '198px',
                                                border: '1px solid red'
                                            }} className="px-2 border-2 rounded-2xl"></div>
                                        </div>

                                        <div className="flex h-full gap-2">
                                            <input type="text" id="exp_month" name="exp_month"
                                                   placeholder="Month"
                                                   className="px-2 border-2 rounded-2xl"/>
                                        </div>
                                        <div className="flex h-full gap-2">
                                            <input type="text" id="exp_year" name="exp_year"
                                                   placeholder="Year"
                                                   className="px-2 border-2 rounded-2xl"/>
                                        </div>
                                        <div className="flex h-full gap-2">
                                            <input type="text" id="email" name="email"
                                                   placeholder="Email"
                                                   className="px-2 border-2 rounded-2xl"/>
                                        </div>
                                        <div className="mt-4">
                                            <input type="checkbox"/>
                                            <label className="ml-1">Prihvatam uslove korišćenja</label>
                                        </div>
                                        <div className="text-center mt-6">
                                            <input type="button" value="Submit" onClick={interceptSubmit}
                                                   className="px-12 py-2 bg-primary hover:bg-secondary text-white font-bold rounded-2xl"/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            */}
        </div>
    );
}

export default withTranslation()(PaymentPriview);
