import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationME from './locales/me/translation.json';
import translationDE from './locales/de/translation.json';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    de: {
        translation: translationDE
    },
    me: {
        translation: translationME
    }
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next,) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: translationEN
            },
            de: {
                translation: translationDE
            },
            me: {
                translation: translationME
            }
        },
        //fallbackLng: 'en',
        debug: true,
        fallbackLng: 'me',
        lng: "me",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }

        // special options for react-i18next
        // learn more: https://react.i18next.com/components/i18next-instance
        /*react: {
            wait: true,
        },*/
    });

export default i18n;