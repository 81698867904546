import React, {useState} from 'react';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const FizickoLice = () => {
    const {t} = useTranslation();

    const [errors, setErrors] = useState({}); // <Record<string, string>>
    const [success, setSuccess] = useState(false);
    const [disableForm, setDisableForm] = useState(false);
    const [formVolonters, setFormVolonters] = useState(false);
    const [formTerms, setFormTerms] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFilesPhotos, setSelectedFilesPhotos] = useState([]);

    const handleFileChange = (event) => {
        const files = event.target.files;
        setSelectedFiles([...selectedFiles, ...files]);
    };

    const handleFileRemove = (index) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
    };

    const handleFilePhotosChange = (event) => {
        const files = event.target.files;
        setSelectedFilesPhotos([...selectedFilesPhotos, ...files]);
    };

    const handleFilePhotosRemove = (index) => {
        const updatedFilesPhotos = [...selectedFilesPhotos];
        updatedFilesPhotos.splice(index, 1);
        setSelectedFilesPhotos(updatedFilesPhotos);
    };

    const [formState, setFormState] = useState({
        form_name_surname: "",
        form_address: "",
        form_email: "",
        form_contact: "",
        form_fb: "",
        form_insta: "",
        form_zr: "",
        form_action_name: "",
        form_desired_amount: "",
        form_calculated_funds: "",
        form_action_area: "Zdravlje",
        form_date_start: "",
        form_date_end: "",
        form_city_country: "",
        form_why: "",
        form_time: "",
        form_volonters: "",
        form_terms: "",
        form_doc_identity: "",
        form_photos_action: ""
    });

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        if (name === "form_desired_amount") {
            const newAmount = value.replace(/,/g, '');
            setFormState((prevState) => ({...prevState, [name]: newAmount.toLocaleString()}));

            const formCalculatedFunds = Number(newAmount) + (Number(newAmount) * 0.09);
            setFormState((prevState) => ({...prevState, ['form_calculated_funds']: formCalculatedFunds}));
        } else {
            setFormState((prevState) => ({...prevState, [name]: value}));
        }
    }

    const handleDateStartChange = (date) => {
        setFormState((prevState) => ({...prevState, ['form_date_start']: date}));
    };
    const handleDateEndChange = (date) => {
        setFormState((prevState) => ({...prevState, ['form_date_end']: date}));
    };

    const validateForm = () => {
        const newErrors = {};

        const {
            form_name_surname,
            form_address,
            form_email,
            form_contact,
            form_fb,
            form_insta,
            form_zr,
            form_action_name,
            form_desired_amount,
            form_calculated_funds,
            form_action_area,
            form_date_start,
            form_date_end,
            form_city_country,
            form_why,
            form_time,
            form_volonters,
            form_terms
        } = formState;

        if (!form_name_surname) {
            newErrors.form_name_surname = t("form_name_surname");
        }

        if (!form_email || !isValidEmail(formState.form_email)) {
            const msg = !formState.form_email ? 'Obavezo je polje' : 'Neispravno unesen '
            newErrors.form_email = msg + '' + t("form_email");
        }

        if (!form_address) {
            newErrors.form_address = t("form_address");
        }

        if (!form_contact) {
            newErrors.form_contact = t("form_contact");
        }

        if (!form_zr) {
            newErrors.form_zr = t("form_zr");
        }

        // Update the errors state variable with the new errors
        setErrors(newErrors);

        // Return true if there are no errors, false otherwise
        return Object.keys(newErrors).length === 0;
    };

    const isValidEmail = (email) => {
        // Regular expression to match email addresses
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Test the email address against the regular expression
        return emailRegex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            return;
        }
        const formData = new FormData();

        selectedFiles.map((file, index) => {
            formData.append(`file_${index}`, file);
        });

        selectedFilesPhotos.map((file, index) => {
            formData.append(`photos_${index}`, file);
        });
        formData.append('name_surname', formState.form_name_surname);
        formData.append('address', formState.form_address);
        formData.append('email', formState.form_email);
        formData.append('contact', formState.form_contact);
        formData.append('fb', formState.form_fb);
        formData.append('insta', formState.form_insta);
        formData.append('zr', formState.form_zr);
        formData.append('action_name', formState.form_action_name);
        formData.append('desired_amount', formState.form_desired_amount);
        formData.append('calculated_funds', formState.form_calculated_funds);
        formData.append('action_area', formState.form_action_area);
        formData.append('date_start', formState.form_date_start);
        formData.append('date_end', formState.form_date_end);
        formData.append('city_country', formState.form_city_country);
        formData.append('why', formState.form_why);
        formData.append('time', formState.form_time);
        formData.append('volonters', formVolonters);
        formData.append('terms', formTerms);
        formData.append('tip', 'fizicko');

        const requestOptions = {
            method: 'POST',
            // headers: {'Content-Type': 'application/json'},
            body: formData // JSON.stringify(formState)
        };
        setDisableForm(true);

        await fetch(`https://app.hemiias.me/api/pokreni-akciju`, requestOptions)
            .then(res => {
                console.log(res);
                setDisableForm(false);
            });

        setSuccess(true);
        setSelectedFiles([]);
        setSelectedFilesPhotos([]);
        setFormState({
            form_name_surname: "",
            form_address: "",
            form_email: "",
            form_contact: "",
            form_fb: "",
            form_insta: "",
            form_zr: "",
            form_action_name: "",
            form_desired_amount: "",
            form_calculated_funds: "",
            form_action_area: "Zdravlje",
            form_date_start: "",
            form_date_end: "",
            form_city_country: "",
            form_why: "",
            form_time: "",
            form_volonters: "",
            form_terms: "",
            form_doc_identity: "",
            form_photos_action: ""
        });
    };

    return (
        <>
            <form onSubmit={handleSubmit} className="mt-12">
                <div className="sm:grid sm:grid-cols-1 md:grid-cols-3 gap-8 max-w-[1100px] mx-auto"
                     style={{padding: "1.5rem"}}>
                    <h1 className="w-full mb-6 md:mb-0 col-span-3 font-bold text-xl md:text-2xl">{t('form_personal_info')}</h1>
                    <div className="w-full mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="name"
                        >
                            {t("form_name_surname")}<span style={{color: "red"}}>*</span>
                        </label>
                        <input
                            className={`appearance-none block w-full bg-neutral-100 text-gray-700 border ${errors.form_name_surname ? 'border-red-500' : ''} 
                            rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                            id="form_name_surname"
                            name="form_name_surname"
                            type="text"
                            value={formState.form_name_surname}
                            onChange={handleInputChange}
                        />
                        {errors.form_name_surname && (
                            <p className="text-red text-xs italic" style={{color: "red"}}>Obavezno
                                polje {errors.form_name_surname}</p>
                        )}
                    </div>

                    <div className="w-full mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="organization"
                        >
                            {t("form_address")}<span style={{color: "red"}}>*</span>
                        </label>
                        <input
                            className={`appearance-none block w-full bg-neutral-100 text-gray-700 border ${errors.form_address ? 'border-red-500' : ''} rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                            id="form_address"
                            name="form_address"
                            type="text"
                            value={formState.form_address}
                            onChange={handleInputChange}
                        />
                        {errors.form_address && (
                            <p className="text-red text-xs italic" style={{color: "red"}}>Obavezno
                                polje {errors.form_address}</p>
                        )}
                    </div>

                    <div className="w-full mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="number"
                        >
                            {t("form_email")}<span style={{color: "red"}}>*</span>
                        </label>
                        <input
                            className={`appearance-none block w-full bg-neutral-100 text-gray-700 border ${errors.form_email ? 'border-red-500' : ''} rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                            id="form_email"
                            name="form_email"
                            type="text"
                            value={formState.form_email}
                            onChange={handleInputChange}
                        />
                        {errors.form_email && (
                            <p className="text-red text-xs italic" style={{color: "red"}}>{errors.form_email}</p>
                        )}
                    </div>

                    <div className="w-full mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="e-mail"
                        >
                            {t("form_contact")}<span style={{color: "red"}}>*</span>
                        </label>
                        <input
                            className={`appearance-none block w-full bg-neutral-100 text-gray-700 border ${errors.form_contact ? 'border-red-500' : ''} rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                            id="form_contact"
                            name="form_contact"
                            type="text"
                            value={formState.form_contact}
                            onChange={handleInputChange}
                        />
                        {errors.form_contact && (
                            <p className="text-red text-xs italic" style={{color: "red"}}>Obavezno
                                polje {errors.form_contact}</p>
                        )}
                    </div>

                    <div className="w-full mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="type-incident"
                        >
                            {t("form_fb")}
                        </label>
                        <input
                            className="appearance-none block w-full bg-neutral-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id="form_fb"
                            name="form_fb"
                            type="text"
                            value={formState.form_fb}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="w-full mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="category"
                        >
                            {t("form_insta")}
                        </label>
                        <input
                            className="appearance-none block w-full bg-neutral-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id="form_insta"
                            name="form_insta"
                            type="text"
                            value={formState.form_insta}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="w-full mb-6 md:mb-0 col-span-3 mt-6">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="comment"
                        >
                            {t("form_zr")}<span style={{color: "red"}}>*</span>
                        </label>
                        <input
                            className={`appearance-none block w-full bg-neutral-100 text-gray-700 border ${errors.form_zr ? 'border-red-500' : ''} rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                            id="form_zr"
                            name="form_zr"
                            type="text"
                            value={formState.form_zr}
                            onChange={handleInputChange}
                        />
                        <small>{t("form_zr_detail")}</small>
                        {errors.form_zr && (
                            <p className="text-red text-xs italic" style={{color: "red"}}>Obavezno
                                polje {errors.form_zr}</p>
                        )}
                    </div>


                    <h1 className="w-full mb-6 md:mb-0 col-span-3 font-bold text-xl md:text-2xl">{t('form_action')}</h1>

                    <div className="w-full mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="name"
                        >
                            {t("form_action_name")}<span style={{color: "red"}}>*</span>
                        </label>
                        <input
                            className={`appearance-none block w-full bg-neutral-100 text-gray-700 border ${errors.form_action_name ? 'border-red-500' : ''} rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                            id="form_action_name"
                            name="form_action_name"
                            type="text"
                            value={formState.form_action_name}
                            onChange={handleInputChange}
                        />
                        {errors.form_action_name && (
                            <p className="text-red text-xs italic" style={{color: "red"}}>Obavezno
                                polje {errors.form_action_name}</p>
                        )}
                    </div>

                    <div className="w-full mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="name"
                        >
                            {t("form_desired_amount")}<span style={{color: "red"}}>*</span>
                        </label>
                        <input
                            className={`appearance-none block w-full bg-neutral-100 text-gray-700 border ${errors.form_desired_amount ? 'border-red-500' : ''} rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                            id="form_desired_amount"
                            name="form_desired_amount"
                            type="number"
                            value={formState.form_desired_amount}
                            onChange={handleInputChange}
                        />
                        <small>{t("form_desired_amount_desc")}</small>
                        {errors.form_desired_amount && (
                            <p className="text-red text-xs italic" style={{color: "red"}}>Obavezno
                                polje {errors.form_desired_amount}</p>
                        )}
                    </div>

                    <div className="w-full mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="name"
                        >
                            {t("form_calculated_funds")}<span style={{color: "red"}}>*</span>
                        </label>
                        <input
                            className={`appearance-none block w-full bg-neutral-100 text-gray-700 border ${errors.form_calculated_funds ? 'border-red-500' : ''} rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                            id="form_calculated_funds"
                            name="form_calculated_funds"
                            type="text"
                            value={formState.form_calculated_funds}
                            onChange={handleInputChange}
                        />
                        <small>{t("form_calculated_funds_desc")}</small>
                        {errors.form_calculated_funds && (
                            <p className="text-red text-xs italic" style={{color: "red"}}>Obavezno
                                polje {errors.form_calculated_funds}</p>
                        )}
                    </div>
                </div>

                <div className="sm:grid sm:grid-cols-1 md:grid-cols-4 gap-x-8 gap-y-3 max-w-[1100px] mx-auto"
                     style={{padding: "1.5rem"}}>

                    <div className="w-full mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="name"
                        >
                            {t("form_action_area")}<span style={{color: "red"}}>*</span>
                        </label>
                        <select
                            className={`block appearance-none w-full bg-neutral-100 border ${errors.form_action_area ? 'border-red-500' : 'border-gray-200'} text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                            id="type-form_action_area"
                            name="form_action_area"
                            value={formState.form_action_area}
                            onChange={handleInputChange}
                        >
                            <option value="Zdravlje">Zdravlje</option>
                            <option value="Kultura">Kultura</option>
                            <option value="Obrazovanje">Obrazovanje</option>
                            <option value="Sport">Sport</option>
                            <option value="Nauka">Nauka</option>
                            <option value="Siromaštvo">Siromaštvo</option>
                            <option value="Ekologija">Ekologija</option>
                            <option value="Telenti">Telenti</option>
                        </select>
                        {errors.form_action_area && (
                            <p className="text-red text-xs italic" style={{color: "red"}}>Obavezno
                                polje {errors.form_action_area}</p>
                        )}
                    </div>

                    <div className="w-full mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="name"
                        >
                            {t("form_date_start")}<span style={{color: "red"}}>*</span>
                        </label>
                        <DatePicker
                            selected={formState.form_date_start}
                            onChange={handleDateStartChange}
                            dateFormat="dd/MM/yyyy"
                            className={`appearance-none block w-full bg-neutral-100 text-gray-700 border ${errors.form_date_start ? 'border-red-500' : ''} rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        />
                        {errors.form_date_start && (
                            <p className="text-red text-xs italic" style={{color: "red"}}>Obavezno
                                polje {errors.form_date_start}</p>
                        )}
                    </div>

                    <div className="w-full mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="name"
                        >
                            {t("form_date_end")}<span style={{color: "red"}}>*</span>
                        </label>
                        <DatePicker
                            selected={formState.form_date_end}
                            onChange={handleDateEndChange}
                            dateFormat="dd/MM/yyyy"
                            className={`appearance-none block w-full bg-neutral-100 text-gray-700 border ${errors.form_date_end ? 'border-red-500' : ''} rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        />
                        {errors.form_date_end && (
                            <p className="text-red text-xs italic" style={{color: "red"}}>Obavezno
                                polje {errors.form_date_end}</p>
                        )}
                    </div>

                    <div className="w-full mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="name"
                        >
                            {t("form_city_country")}
                        </label>
                        <input
                            className="appearance-none block w-full bg-neutral-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id="form_city_country"
                            name="form_city_country"
                            type="text"
                            value={formState.form_city_country}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="w-full mb-6 md:mb-0 col-span-4">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="name"
                        >
                            {t("form_why")}<span style={{color: "red"}}>*</span>
                        </label>
                        <textarea
                            className={`appearance-none block w-full bg-neutral-100 text-gray-700 border ${errors.form_why ? 'border-red-500' : ''} rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white min-h-[140px]`}
                            id="form_why"
                            name="form_why"
                            value={formState.form_why}
                            onChange={handleInputChange}
                        />
                        {errors.form_why && (
                            <p className="text-red text-xs italic" style={{color: "red"}}>Obavezno
                                polje {errors.form_why}</p>
                        )}
                    </div>

                    <div className="w-full mb-6 md:mb-0 col-span-4">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="name"
                        >
                            {t("form_time")}<span style={{color: "red"}}>*</span>
                        </label>
                        <input
                            className={`appearance-none block w-full bg-neutral-100 text-gray-700 border ${errors.form_time ? 'border-red-500' : ''} rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                            id="form_time"
                            name="form_time"
                            type="text"
                            value={formState.form_time}
                            onChange={handleInputChange}
                        />
                        <small>{t("form_time_detail")}</small>
                        {errors.form_time && (
                            <p className="text-red text-xs italic" style={{color: "red"}}>Obavezno
                                polje {errors.form_time}</p>
                        )}
                    </div>

                    <div className="w-full mb-6 md:mb-0 col-span-4">
                        <input
                            id="form_volonters"
                            name="form_volonters"
                            type="checkbox"
                            value={formState.form_volonters}
                            onClick={() => setFormVolonters(!formVolonters)}
                        />
                        <label
                            className="uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="form_volonters"
                            style={{marginLeft: '10px'}}
                        >
                            {t("form_volonters")}
                        </label>
                    </div>

                    <div className="w-full mb-6 md:mb-0 col-span-4">
                        <input
                            id="form_terms"
                            name="form_terms"
                            type="checkbox"
                            value={formState.form_terms}
                            onClick={() => setFormTerms(!formTerms)}
                        />
                        <label
                            className="uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="form_terms"
                            style={{marginLeft: '10px'}}
                        >
                            {t("form_terms")} <a href="/uslovi-koriscenja" target="_blank"
                                                 style={{color: "red"}}>{t("form_terms2")}</a>
                        </label>
                    </div>


                    <h1 className="w-full mb-6 md:mb-0 col-span-3 font-bold text-xl md:text-2xl">{t('form_doc')}</h1>

                    <div className="w-full mb-6 md:mb-0 col-span-2">
                        <div
                            className="w-full h-33 border-dashed border-2 border-gray-300 rounded-md flex items-center justify-center">
                            <label htmlFor="file-upload" className="cursor-pointer"
                                   style={{marginTop: "20px", marginBottom: "20px"}}>
                                <div className="relative">
                                        <span className="text-gray-600 flex flex-col items-center">
                                            <img src="/img/icons/upload-file.svg" alt="Upload files" width="50"/>
                                            <p className="font-semibold">
                                                {t("form_doc_identity")}</p>
                                            <p><small>Obavezno je priložiti barem 1 fajl.</small></p>
                                            <p><small>Dozvoljeno je dodati najviše 20 fajlova.</small></p>
                                            <p><small>Dozvoljeno je najviše 10.00Mbpo fajlu.</small></p>
                                            <p><small>Dozvoljeni tipovi fajlova su MSWord, PDF i slike.</small></p>
                                        </span>
                                    <input id="file-upload" type="file" multiple onChange={handleFileChange}
                                           className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"/>
                                </div>
                            </label>
                        </div>
                        <ul>
                            {selectedFiles.map((file, index) => (
                                <li key={index}>
                                    {file.name}
                                    <span onClick={() => handleFileRemove(index)}
                                          style={{marginLeft: "10px", fontWeight: "500", cursor: "pointer"}}>x</span>
                                </li>
                            ))}
                        </ul>
                        <small style={{fontSize: "9px"}}>{t("form_doc_identity_desc")}</small>
                    </div>

                    <div className="w-full mb-6 md:mb-0 col-span-2">
                        <div
                            className="w-full h-33 border-dashed border-2 border-gray-300 rounded-md flex items-center justify-center">
                            <label htmlFor="file-upload" className="cursor-pointer"
                                   style={{marginTop: "20px", marginBottom: "20px"}}>
                                <div className="relative">
                                        <span className="text-gray-600 flex flex-col items-center">
                                            <img src="/img/icons/upload-file.svg" alt="Upload files" width="50"/>
                                            <p className="font-semibold">
                                                {t("form_action_photos")}</p>
                                            <p><small>Obavezno je priložiti barem 1 fajl.</small></p>
                                            <p><small>Dozvoljeno je dodati najviše 20 fajlova.</small></p>
                                            <p><small>Dozvoljeno je najviše 10.00Mbpo fajlu.</small></p>
                                            <p><small>Dozvoljeni tipovi fajlova su MSWord, PDF i slike.</small></p>
                                        </span>
                                    <input id="file-upload" type="file" multiple onChange={handleFilePhotosChange}
                                           className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"/>
                                </div>
                            </label>
                        </div>
                        <ul>
                            {selectedFilesPhotos.map((file, index) => (
                                <li key={index}>
                                    {file.name}
                                    <span onClick={() => handleFilePhotosRemove(index)}
                                          style={{marginLeft: "10px", fontWeight: "500", cursor: "pointer"}}>x</span>
                                </li>
                            ))}
                        </ul>
                        <small style={{fontSize: "9px"}}>{t("form_action_photos_desc")}</small>
                    </div>

                    <div className="w-full mb-6 md:mb-0 col-span-4 text-center">
                        {success && (
                            <div>
                                <p className="text-green-600 text-base">
                                    {t("success")}
                                </p>
                            </div>
                        )}
                        {!disableForm ? (
                            <button
                                className="font-semibold text-lg bg-primary px-8 py-2 rounded-xl text-white cursor-pointer transition-colors hover:bg-secondary"
                            >
                                {t("form_submit")}
                            </button>
                        ) : (
                            <span>sending...</span>
                        )}
                    </div>

                </div>
            </form>
        </>
    );
};

export default FizickoLice;