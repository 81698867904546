import React, {useEffect, useState} from "react";
import IstaknuteCard from "./istaknute-card";
import Akcije from "./akcije";
import {withTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const IstaknuteAkcije = (props) => {
    const {t, i18n} = props;
    const {REACT_APP_API_URL, REACT_APP_BACKEND} = process.env;
    const [izdvojeneAkcije, setIzdvojeneAkcije] = useState([]);

    const navigate = useNavigate();

    const getIzdvojeneAkcije = async () => {
        fetch(REACT_APP_API_URL + "akcije-izdvojene?lng=" + i18n.language)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                setIzdvojeneAkcije(result);
            });
    };

    useEffect(() => {
        getIzdvojeneAkcije();
    }, [i18n.language]);

    const progress = "absolute top-0 left-0 h-full";
    return (
        <div className="px-4 mt-20">
            <h1 className="text-3xl font-bold mb-4 text-center mb-8">
                {t('featured_actions')}
            </h1>
            <Akcije akcije={izdvojeneAkcije} home={'true'}/>
        </div>
    );
};

export default withTranslation()(IstaknuteAkcije);
