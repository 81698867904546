import { Outlet } from "react-router-dom";
import PaymentPriview from "../../components/payment-priview/payment-priview";

const Payment = () => {
    return (
        <div>
            <Outlet />
            <PaymentPriview></PaymentPriview>
        </div>
    )
}

export default Payment;
