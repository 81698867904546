import {Outlet} from "react-router-dom";
import PaymentSuccessPriview from "../../components/payment-priview/payment-success-priview";

const PaymentSuccess = () => {
    return (
        <div>
            <Outlet/>
            <PaymentSuccessPriview></PaymentSuccessPriview>
        </div>
    )
}

export default PaymentSuccess;
